import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useOIDCCallback } from '@/hooks/useOIDCCallback'
import { useLocalStorage } from '@/utils/localstorage/localstorage'
import { redirectToLogin, validateSession } from '@/services/auth'

import ComponentPreview from '../views/ComponentPreview.vue'
import NexusView from '@/views/ReportsView/NexusView.vue'
import NexusNewReportView from '@/views/ReportsView/NexusNewReportView.vue'
import NexusEditReportView from '@/views/ReportsView/NexusEditReportView.vue'
import NexusReportView from '@/views/ReportsView/NexusReportView.vue'

const { getStorage, removeStorage } = useLocalStorage()

export const routeNames = {
  reports: 'NexusView',
  newReport: 'NexusNewReportView',
  preview: 'Preview',
  editReport: 'NexusEditReportView',
  viewReport: 'NexusReportView'
}
export const hidableHeaderRoutes: string[] = [routeNames.editReport, routeNames.viewReport];

const activeSSO = process.env.VUE_APP_ENV !== "development"


export const routes: Array<RouteRecordRaw> = [
  {
    path: '/nexus',
    component: { template: '', beforeCreate() { this.$router.push({name: routeNames.reports}) } }
  },
  {
    path: '/nexus/reports',
    name: routeNames.reports,
    component: NexusView
  },
  {
    path: '/nexus/reports/new',
    name: routeNames.newReport,
    component: NexusNewReportView
  },

  {
    path: '/nexus/reports/edit/:id',
    name: routeNames.editReport,
    component: NexusEditReportView
  },
  {
    path: '/nexus/reports/view/:id',
    name: routeNames.viewReport,
    component: NexusReportView
  },
  {
    path: '/nexus/preview',
    name: routeNames.preview,
    component: ComponentPreview
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  function redirect() {
    next({
      path: getStorage("before-route-path") as string,
      replace: true
    })

    removeStorage("before-route-path")
  }

  if (activeSSO) {

    if (to.query.code) {
      useOIDCCallback(to.query.code as string, redirect);
      return
    }

    if (!validateSession()) {
      redirectToLogin(to.fullPath);
    }

  }

  next()
})


export default router
