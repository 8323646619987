import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "d-flex align-center ga-8 w-100 ma-auto px-4",
  style: {"position":"relative"},
  id: "menu"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "d-flex align-center justify-space-between w-100" }
const _hoisted_5 = { class: "d-flex align-center" }

import imgClearsale from "@/assets/logo_mobile.png";
import UserMenu from "./UserMenu/UserMenu.vue";
import AppsMenu from "./AppsMenu/AppsMenu.vue";
import { ref, watch } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useReportsStore } from "@/store/ReportsStore";
import { hidableHeaderRoutes } from "@/router";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'OneMenu',
  props: {
    showLogo: { type: Boolean, default: true }
  },
  setup(__props: any) {


const appsMenu = ref(false);
const userMenu = ref(false);
const { mdAndUp } = useDisplay();
const route = useRoute();
const { $state } = useReportsStore();

function shouldRenderHeader() : boolean {
  return mdAndUp.value || !$state.isLandScape || !hidableHeaderRoutes.includes(route.name as string);
}

watch(appsMenu, () => {
  if (appsMenu.value) {
    userMenu.value = false;
  }
});

watch(userMenu, () => {
  if (userMenu.value) {
    appsMenu.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (shouldRenderHeader())
    ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showLogo)
            ? _renderSlot(_ctx.$slots, "logo", { key: 0 }, () => [
                _createElementVNode("img", {
                  src: _unref(imgClearsale),
                  alt: "Clearsale Logo",
                  height: "48px"
                }, null, 8, _hoisted_3)
              ])
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
              ])
            ]),
            _createElementVNode("nav", null, [
              _renderSlot(_ctx.$slots, "nav", {}, () => [
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "links", {}, () => [
                    _createVNode(AppsMenu, {
                      modelValue: appsMenu.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((appsMenu).value = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(UserMenu, {
                      modelValue: userMenu.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((userMenu).value = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})