<template>
  <div
    class="d-flex flex-column ga-4"
    :class="mdAndUp || 'px-4'"
    v-if="reportEdit"
  >
    <div
      class="w-full bg-cs_secondary_container rounded-xl"
      :style="{
        height: mdAndUp || !$state.isLandScape ? '520px' : '100vh',
        borderRadius: '16px',
        overflow: 'hidden',
      }"
    >
      <ReportBI
        v-if="reportEdit.embedToken.token"
        :mode="ViewMode.Edit"
        :report="reportEdit"
        :report-id="reportIdLoad"
        @loaded="onReportLoaded"
      ></ReportBI>
    </div>
    <div
      class="d-flex justify-space-between align-center"
      v-if="mdAndUp || !$state.isLandScape"
    >
      <div class="d-flex align-center ga-4">
        <div class="pb-7">
          <v-btn
            color="cs_surface_container"
            icon="mdi-arrow-left"
            elevation="0"
            size="40px"
            @click="
              () => {
                if (hasModifiedForm) {
                  redirectRouterToDiscard = routeNames.reports;
                  modalDiscard = true;
                }

                router.back();
              }
            "
            data-testid="back-button"
          ></v-btn>
        </div>
        <div style="min-width: 210px">
          <v-text-field
            v-model="templateName"
            style="width: 100%"
            :label="t(label.AddNewPage.name)"
            variant="outlined"
            :rules="[
              inputTemplateNameReturnMessageRules.required,
              inputTemplateNameReturnMessageRules.maxLength,
            ]"
          ></v-text-field>
        </div>
      </div>
      <div class="pb-6">
        <v-btn
          :color="themeName === 'dark' ? 'surface' : 'surface-variant'"
          class="bg-on-surface-variant"
          :disabled="!enabledButton"
          elevation="0"
          @click="handleSaveReport"
        >
          <div class="d-flex ga-2 text-cs_primary">
            <v-icon icon="mdi-content-save-outline"></v-icon>
            {{ t(label.EditViewReportPage.Save.title) }}
          </div>
        </v-btn>
      </div>
    </div>
  </div>

  <v-dialog v-model="modalDiscard" width="auto">
    <DiscardModal
      v-if="modalDiscard"
      @cancel="modalDiscard = false"
      @confirm="handleDiscardNewReport"
    ></DiscardModal>
  </v-dialog>

  <SnackbarClose
    v-if="!mdAndUp && !$state.isLandScape"
    v-model="rotateSmartPhoneSnackbar"
    @close="rotateSmartPhoneSnackbar = false"
  >
    <span class="text-white">{{
      t(label.EditViewReportPage.RotateDevice.title)
    }}</span>
  </SnackbarClose>
</template>

<script setup lang="ts">
import { routeNames } from "@/router/index";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useDisplay, useTheme } from "vuetify";
import {
  onBeforeRouteLeave,
  RouteRecordNameGeneric,
  useRoute,
  useRouter,
} from "vue-router";

import DiscardModal from "@/components/nexus/DiscardModal/DiscardModal.vue";
import ReportBI from "@/components/nexus/ReportBI/ReportBI.vue";
import { useReportsStore } from "@/store/ReportsStore";
import {
  getReportEmbed,
  patchReportEdit,
  ResponseReportEdit,
} from "@/api/resources/ReportsResource/nexusResource";
import { Embed } from "powerbi-client";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import { useLocalStorage } from "@/utils/localstorage/localstorage";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

enum ViewMode {
  View = 0,
  Edit = 1,
}

const { t } = useI18n();
const { mdAndUp } = useDisplay();
const router = useRouter();
const route = useRoute();
const { $state, $patch } = useReportsStore();
const { name: themeName } = useTheme();
const { getStorage } = useLocalStorage();

const reportEdit = ref<ResponseReportEdit | undefined>(undefined);
const rotateSmartPhoneSnackbar = ref(false);

const inputTemplateNameReturnMessageRules = {
  required: (inputValue: string) => !!inputValue || "Name is required",
  maxLength: (inputValue: string) =>
    (inputValue && inputValue.length <= 50) ||
    "Name must be less than 50 characters",
};

const templateName = ref("");
const reportEmbed = ref<Embed | undefined>(undefined);
const templateHasModified = ref(false);
const enabledButton = ref(false);
const buttonDiscardHasClicked = ref(false);
const saveButtonHasClicked = ref(false);
const modalDiscard = ref(false);
const redirectRouterToDiscard = ref<RouteRecordNameGeneric>("");
const inputTemplateNameRules = {
  required: (inputValue: string) => !!inputValue,
  maxLength: (inputValue: string) => inputValue && inputValue.length <= 50,
};

const reportIdLoad =
  computed(() => route.params.id as string) &&
  process.env.VUE_APP_ENV !== "development"
    ? (route.params.id as string)
    : getStorage("report-id") ?? "1711d2b9-6375-40bd-b273-22e64d9b4fa7";

const hasModifiedForm = computed(() => {
  return templateHasModified.value || hasModifedInput.value;
});

const hasValidateForm = computed(() => {
  return (
    inputTemplateNameRules.required(templateName.value) &&
    inputTemplateNameRules.maxLength(templateName.value)
  );
});

const onEvents = [
  "selectionChanged",
  "swipeEnd",
  "filtersApplied",
  "visualClicked",
  "pageChanged",
  "dataHyperlinkClicked",
  "dataSelected",
  "buttonClicked",
  "blur",
];

function onReportLoaded(report: Embed) {
  reportEmbed.value = report;
  reportEmbed.value.on("saved", () => {
    patchReportEdit({ name: templateName.value, reportId: reportIdLoad }).then(
      () => {
        templateHasModified.value = false;
      }
    );
  });
  reportEmbed.value.on("blur", () => {
    templateHasModified.value = true;
  });

  onEvents.forEach((event) => {
    if (reportEmbed.value)
      reportEmbed.value.on(event, () => {
        templateHasModified.value = true;
      });
  });
}

function handleSaveReport() {
  if (reportEmbed.value) reportEmbed.value.save();
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  if (hasModifiedForm.value) event.preventDefault();
};

function handleDiscardNewReport() {
  buttonDiscardHasClicked.value = true;
  modalDiscard.value = false;
  router.push({
    name: redirectRouterToDiscard.value?.toString().includes(routeNames.reports)
      ? redirectRouterToDiscard.value
      : routeNames.viewReport,
  });
}

onMounted(() => {
  rotateSmartPhoneSnackbar.value = true;
  window.addEventListener("beforeunload", handleBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);

  if (reportEmbed.value) {
    onEvents.forEach((event) => {
      reportEmbed.value?.off(event);
    });
  }
});

onMounted(() => {
  if (route.params.id as string) {
    getReportEmbed(route.params.id as string).then((response) => {
      reportEdit.value = response;
      templateName.value = response.reportName;
    });
    return;
  }
  router.push({
    name: routeNames.reports,
  });
});

const hasModifedInput = ref(false);

watch(
  templateName,
  (newValue, oldValue) => {
    if (oldValue && oldValue.length > 0 && newValue !== oldValue) {
      hasModifedInput.value = true;
    }
    if (hasValidateForm.value) {
      enabledButton.value = true;
      return;
    }
    enabledButton.value = false;
  },
  { deep: true, immediate: true }
);

onBeforeRouteLeave(async (to, from, next) => {
  redirectRouterToDiscard.value = to.name;

  if (hasModifiedForm.value) {
    if (
      buttonDiscardHasClicked.value ||
      (saveButtonHasClicked.value && hasValidateForm.value)
    ) {
      next();
      return;
    }
    modalDiscard.value = true;
    next(false);
    return;
  }
  next();
});
</script>

<style scoped lang="scss">
.snackbar {
  position: relative;
  top: 0;
  * {
    position: absolute;
    top: 0 !important;
  }
}
</style>
