import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-cs_on_surface_variant" }
const _hoisted_2 = {
  class: "text-cs_on_surface_variant",
  style: {"justify-self":"center"}
}
const _hoisted_3 = {
  style: {"justify-self":"end"},
  class: "ml-2 text-cs_on_surface_variant text-overline"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["datetime"]
const _hoisted_6 = { class: "d-flex justify-end align-end h-100" }
const _hoisted_7 = { datetime: "" }

import { useDate, useDisplay } from "vuetify/lib/framework.mjs";
import CardItemDesktop from "./CardItemDesktop.vue";
import CardItemMobile from "./CardItemMobile.vue";
import { computed, ref } from "vue";
import { ReportsStore } from "@/store/ReportsStore";
import DeleteModal from "../DeleteModal/DeleteModal.vue";
import { Report } from "@/api/resources/ReportsResource/nexusResource";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemReport',
  props: {
    reports: {}
  },
  emits: ["delete", "edit", "select"],
  setup(__props: any, { emit: __emit }) {



const emits = __emit;

const dateUtilityVuetify = useDate();
const { mdAndUp } = useDisplay();

const confirmDeleteReport = ref(false);
const report = ref<Report>();

function handleDeleteReport() {
  if (report.value) {
    emits("delete", report.value);
    confirmDeleteReport.value = false;
  }
}

function handleEditReport(report: Report) {
  emits("edit", report);
}

function handleSelectReport(report: Report) {
  emits("select", report);
}

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

const formatDate = (date: Date) =>
  computed(() => {
    return isTodayDate(date)
      ? dateUtilityVuetify.format(date, "fullTime12h")
      : dateUtilityVuetify.format(date, "fullDate");
  });

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reports, (item) => {
    return (_openBlock(), _createElementBlock(_Fragment, {
      key: item.name
    }, [
      (_unref(mdAndUp))
        ? (_openBlock(), _createBlock(CardItemDesktop, {
            key: 0,
            item: item,
            actions: true,
            columns: 3,
            icon: {
        name: 'mdi-file-document-outline',
        buttonColor: 'surface-variant-bright',
        color: 'primary',
      },
            selected: item.selected,
            "onUpdate:selected": ($event: any) => ((item.selected) = $event),
            onClick: _withModifiers(($event: any) => (handleSelectReport(item)), ["stop"])
          }, {
            default: _withCtx((props) => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1),
              _createElementVNode("span", _hoisted_2, _toDisplayString(item.ownerName), 1),
              _createElementVNode("span", _hoisted_3, [
                (props.data.isHovering)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_v_btn, {
                        icon: "",
                        width: "40px",
                        height: "40px",
                        variant: "text",
                        onClick: _withModifiers(($event: any) => (handleEditReport(item)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-pencil",
                            color: "tertiary"
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        width: "40px",
                        height: "40px",
                        variant: "text",
                        onClick: _withModifiers(
                () => {
                  report.value = item;
                  confirmDeleteReport.value = true;
                }
              , ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-trash-can-outline",
                            color: "tertiary"
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : (_openBlock(), _createElementBlock("time", {
                      key: 1,
                      datetime: item.updatedAt.toDateString
                    }, _toDisplayString(formatDate(new Date(item.updatedAt))), 9, _hoisted_5))
              ])
            ]),
            _: 2
          }, 1032, ["item", "selected", "onUpdate:selected", "onClick"]))
        : (_openBlock(), _createBlock(CardItemMobile, {
            key: 1,
            item: item,
            header: false,
            icon: {
        name: 'mdi-file-document-outline',
        buttonColor: 'surface-variant-bright',
        color: 'primary',
      },
            onDoubletouch: ($event: any) => (handleSelectReport(item))
          }, {
            back: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_v_btn, {
                  icon: "",
                  width: "40px",
                  height: "40px",
                  variant: "text",
                  onClick: _withModifiers(($event: any) => (handleEditReport(item)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      icon: "mdi-pencil",
                      color: "tertiary"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_v_btn, {
                  icon: "",
                  width: "40px",
                  height: "40px",
                  variant: "text",
                  onClick: _withModifiers(
              () => {
                report.value = item;
                confirmDeleteReport.value = true;
              }
            , ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      icon: "mdi-trash-can-outline",
                      color: "tertiary"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("h5", null, _toDisplayString(item.name), 1),
                _createElementVNode("span", null, _toDisplayString(item.ownerName), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("time", _hoisted_7, _toDisplayString(formatDate(new Date(item.updatedAt))), 1)
              ])
            ]),
            _: 2
          }, 1032, ["item", "onDoubletouch"])),
      _createVNode(_component_v_dialog, {
        modelValue: confirmDeleteReport.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((confirmDeleteReport).value = $event)),
        width: "auto"
      }, {
        default: _withCtx(() => [
          (confirmDeleteReport.value)
            ? (_openBlock(), _createBlock(DeleteModal, {
                key: 0,
                onCancel: _cache[0] || (_cache[0] = ($event: any) => (confirmDeleteReport.value = false)),
                onConfirm: handleDeleteReport
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 64))
  }), 128))
}
}

})