import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-4" }
const _hoisted_2 = {
  class: "text-cs_on_surface_variant",
  style: {"font-size":"14px"}
}
const _hoisted_3 = { class: "text-cs_on_surface_variant" }

import { useClassByTheme } from "@/components/hooks/useClassByTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItemIMG',
  props: {
    img: {},
    title: {},
    subtitle: {},
    height: {},
    width: {},
    active: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any) {



const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};



const className = useClassByTheme(classTheme);

return (_ctx: any,_cache: any) => {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_hover, null, {
    default: _withCtx(({ isHovering, props }) => [
      _createVNode(_component_v_card, _mergeProps({
        "data-testid": "card",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        elevation: "0"
      }, props, {
        class: [[
          _unref(className),
          isHovering && 'bg-cs_ref_palette_neutral99',
          _ctx.active && 'bg-cs_surface_container',
        ], "d-flex ga-4 rounded-xl ga-4 pa-2 cursor-pointer"]
      }), {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_v_img, {
              class: "rounded-xl mx-auto",
              "lazy-src": _ctx.img?.src ?? 'https://picsum.photos/id/237/120/80',
              src: _ctx.img?.src ?? 'https://picsum.photos/id/237/120/80',
              alt: _ctx.img?.alt ?? 'picsum',
              width: _ctx.width ?? '120px',
              height: _ctx.height ?? '80px'
            }, null, 8, ["lazy-src", "src", "alt", "width", "height"])
          ]),
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.title), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1)
            ])
          ])
        ]),
        _: 2
      }, 1040, ["class"])
    ]),
    _: 3
  }))
}
}

})