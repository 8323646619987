import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomHero',
  props: {
    label: {},
    srcImage: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const { mdAndUp } = useDisplay();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": _unref(generateTestId)(_unref(CustomHeroElements).Content),
    id: "hero",
    style: _normalizeStyle({
      backgroundImage: _unref(mdAndUp) ? `url('${_ctx.srcImage}')` : `url('${_ctx.srcImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: `${_unref(mdAndUp) ? '24px' : '12px'}`,
      height: _ctx.height,
      width: _ctx.width,
    }),
    class: "title h-100 w-100 position-relative"
  }, [
    _createElementVNode("h1", {
      style: _normalizeStyle({
        fontSize: _unref(mdAndUp) ? '36px' : '44px',
        lineHeight: _unref(mdAndUp) ? '64px' : '56px',
      })
    }, _toDisplayString(_ctx.label), 5),
    _renderSlot(_ctx.$slots, "default")
  ], 12, _hoisted_1))
}
}

})