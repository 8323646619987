<template>
  <div
    style="
      background-color: white;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
    v-if="showApp"
  >
    <v-theme-provider>
      <div
        :class="className"
        style="flex: 1; display: flex; flex-direction: column"
      >
        <OneMenu v-if="load" class="my-4"></OneMenu>
        <div style="max-width: 1040px" class="w-100 mx-auto">
          <RouterView />
        </div>
      </div>
      <FooterLogo :class="className" />
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import "@/plugins/gsap";

import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import FooterLogo from "@/components/shared/Footer/FooterLogo.vue";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { validateSession } from "@/services/auth";
import { useReportsStore } from "@/store/ReportsStore";
import { enableMocking } from "@/api/mocks/browser";

import { useTheme } from "vuetify";

import imgNexus from "@/assets/logo_nexus.svg";
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from "@unhead/vue";

const headInfo = useHead({
  title: "Nexus",
  link: [{ rel: "icon", href: imgNexus, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const { $patch } = useReportsStore();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const { global } = useTheme();

let theme = ref();

const showApp = ref(false);
const router = useRouter();

const className = useClassByTheme(classTheme);

const activeSSO = process.env.VUE_APP_ENV !== "development";

addEventListener("access-token", () => {
  showApp.value = true;
});

const updateOrientation = () => {
  const angleDevice = screen.orientation.angle;

  if (angleDevice === 0) {
    $patch({ isLandScape: false });
    return;
  }

  $patch({ isLandScape: true });
};

onBeforeMount(() => {
  theme.value = localStorage.getItem("theme");
  global.name.value = theme.value !== "dark" ? "light" : "dark";
});

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
  screen.orientation.addEventListener("change", updateOrientation);
});

onUnmounted(() => {
  screen.orientation.removeEventListener("change", updateOrientation);

  headInfo.dispose();
});

const load = ref(false);

router.beforeResolve((to, from, next) => {
  enableMocking().finally(() => {
    next();
    load.value = true;
  });
});
</script>
