import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import AppsMenuDesktop from "./AppsMenuDesktop.vue";
import AppsMenuMobile from "./AppsMenuMobile.vue";
import { getApps } from "@/api/resources/oneResource";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppsMenu',
  props: {
    "modelValue": { default: false },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const { mdAndUp } = useDisplay();
const router = useRouter();

const model = _useModel(__props, "modelValue");
const openAppsMenu = ref(model);

function handleSelectRouterTo(link: NewMenu.Link) {
  router.push({ name: link.routerName });
}

const loading = ref(true);

const apps = [
  {
    id: "Apps",
    label: "One",
    routerName: "OneView",
    order: 1,
    subLinks: [],
  },
  {
    id: "Apps",
    label: "Shield",
    routerName: "ShieldView",
    order: 2,
    subLinks: [],
  },
  {
    id: "Apps",
    label: "Nexus",
    routerName: "NexusView",
    order: 3,
    subLinks: [],
  },
];

const links = ref<NewMenu.Link[]>();

function loadAppsIsShow() {
  getApps()
    .then((res) => {
      links.value = apps.filter((app) => res.results.includes(app.label));
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(() => {
  loadAppsIsShow();
});

router.beforeEach(() => {
  loadAppsIsShow();
});

return (_ctx: any,_cache: any) => {
  return (!loading.value && links.value?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(mdAndUp))
          ? (_openBlock(), _createBlock(AppsMenuDesktop, {
              key: 0,
              modelValue: openAppsMenu.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((openAppsMenu).value = $event)),
              links: links.value,
              onRouterTo: handleSelectRouterTo
            }, null, 8, ["modelValue", "links"]))
          : (_openBlock(), _createBlock(AppsMenuMobile, {
              key: 1,
              modelValue: openAppsMenu.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((openAppsMenu).value = $event)),
              links: links.value,
              onRouterTo: handleSelectRouterTo
            }, null, 8, ["modelValue", "links"]))
      ]))
    : _createCommentVNode("", true)
}
}

})