import { useLocalStorage } from "@/utils/localstorage/localstorage"

export const BASE_URL = process.env.VUE_APP_BASE_URL_API ?? "";

export type SuggestionItem = {
  id: string,
  value: string
  subValue: string,
}

export interface Report {
  id: string
  name: string
  ownerName: string
  updatedAt: Date
}

interface ResponseReport {
  results: Report[]
  canAddNewReport: boolean,
}

export interface RequestReport {
  filters?: {
    ids?: string[],
    ownerIds?: string[],
    updatedAtFrom?: Date,
    updatedAtTo?: Date,
  }
  pagging?: {
    page?: number,
    limit?: number
  }
}

export enum SuggestionType {
  None = 0,
  Report = 1,
  ReportOwner = 2
}
export type RequestDataSuggestion = {
  type: SuggestionType,
  value: string,

  limit: number
}

export type ResponseDataSuggestion = {
  results: SuggestionItem[]
}

export type RequestDataCreateReport = {
  baseReportId: string,
  name: string
}

export type ResponseDataCreateReport = {
  reportId: string
}

export interface TemplateReports {
  baseReportId: string;
  tag: string;
  // selected: boolean;
}

interface ResponseDataTemplateReports {
  canAddNewReport: boolean,
  results: TemplateReports[]
}

export interface ResponseReportEdit {
  ownerName: string;
  reportName: string;
  updatedAt: Date;
  embedToken: {
    token: string;
    tokenId: string;
    expiration: Date;
  };
  embedUrl: string;
  datasetIds: string[];
}


export interface RequestReportEdit {
  reportId: string
  name: string
}

const { getStorage } = useLocalStorage()

export function getReports(request?: RequestReport): Promise<ResponseReport> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }
  return fetch(
    BASE_URL + "/nexus/powerbi/reports/search",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      },
      body: JSON.stringify(request)
    }
  ).then(response => response.json())
}

export function deleteReportAPI(id: string): Promise<ResponseReport> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }
  return fetch(
    BASE_URL + `/nexus/powerbi/reports/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      }
    }
  ).then(response => response.json())
}


export function getSuggestionAPIPOST(body: RequestDataSuggestion): Promise<ResponseDataSuggestion> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }
  return fetch(
    BASE_URL + "/nexus/suggestions",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      },
      body: JSON.stringify(body),
    }
  ).then(response => response.json())
}

export function postCreateReport(request: RequestDataCreateReport): Promise<ResponseDataCreateReport> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }

  return fetch(
    BASE_URL + "/nexus/powerbi/reports",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      },
      body: JSON.stringify(request)
    }
  ).then(response => {
    return response.json()
  })
}

export function getListBaseReport(): Promise<ResponseDataTemplateReports> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }

  return fetch(
    BASE_URL + "/nexus/powerbi/basereports",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      },
    }
  ).then(response => {
    return response.json()
  })
}


export function getReportEmbed(id: string): Promise<ResponseReportEdit> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }
  return fetch(
    BASE_URL + `/nexus/powerbi/reports/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      },
    }
  ).then(response => {
    return response.json()
  })
}


export function patchReportEdit(request: RequestReportEdit): Promise<unknown> {
  const token = JSON.parse(getStorage('token') ?? '{"access_token": ""}') as { access_token: string }

  return fetch(
    BASE_URL + "/nexus/powerbi/reports",
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`
      },
      body: JSON.stringify(request)
    }
  ).then(response => {
    return response
  })
}
