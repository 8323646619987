<template>
  <v-snackbar v-model="model" color="inverse-surface">
    <slot>
      <span class="text-white">
        File limit reached. Consider deleting to add more.
      </span>
    </slot>

    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="handleCloseSnackbar"
        icon="mdi-close"
      >
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
const model = defineModel({ default: false });
const emits = defineEmits<(e: "close") => void>();

function handleCloseSnackbar() {
  emits("close");
}
</script>
