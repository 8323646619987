<template>
  <div
    :data-testid="generateTestId(CustomHeroElements.Content)"
    id="hero"
    :style="{
      backgroundImage: mdAndUp ? `url('${srcImage}')` : `url('${srcImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      padding: `${mdAndUp ? '24px' : '12px'}`,
      height,
      width,
    }"
    class="title h-100 w-100 position-relative"
  >
    <h1
      :style="{
        fontSize: mdAndUp ? '36px' : '44px',
        lineHeight: mdAndUp ? '64px' : '56px',
      }"
    >
      {{ label }}
    </h1>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
const { mdAndUp } = useDisplay();

defineProps<{
  label?: string;
  srcImage?: string;
  width?: string;
  height?: string;
}>();
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  box-sizing: border-box;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: var(--mdui-shape-corner-extra-large);

  display: flex;

  justify-content: space-between;
  align-items: flex-end;

  & h1 {
    margin: 0;
    font-size: 57px;
    font-weight: 400;
  }

  & .status-cards {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    gap: 8px;

    & h2 {
      font-size: 36px;
      font-weight: 400;
    }

    .v-card-text {
      padding: 0;
      flex: 0;

      & > span {
        font-size: 14px;

        & v-icon {
          font-size: 8px;
        }
      }

      & > p {
        margin: 0;
        font-size: 28px;
        font-weight: 400;
        color: black;

        & span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
