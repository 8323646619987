<template>
  <main class="d-flex flex-column flex-grow-1 w-100">
    <div
      :class="mdAndUp || 'px-4'"
      :style="{ height: mdAndUp ? '220px' : '120px' }"
    >
      <CustomHero :label="label.AddNewPage.title" :src-image="imgHero"></CustomHero>
    </div>
    <div class="mb-4 mt-2">
      <WidthScrollContainer
        width="600px"
        resizeBy="600px"
        :height="elementOnTop ? 48 : 58"
        :pined-style="elementOnTop ? 'z-index:9999;' : 'height: auto'"
        :class="className"
        style="padding: 0px"
        @scroll-on-top="handleScrollOnTop"
      >
        <div class="d-flex justify-space-between" :class="mdAndUp || 'px-4'">
          <div class="w-100">
            <v-btn
              color="cs_surface_container"
              icon="mdi-arrow-left"
              elevation="0"
              :size="elementOnTop ? '40px' : '48px'"
              @click="handleBackButton()"
              ref="refIcon"
              data-testid="back-button"
            ></v-btn>
          </div>
          <v-btn
            class="text-primary save-button"
            :color="themeName === 'dark' ? 'surface' : 'surface-variant'"
            :style="{ height: elementOnTop ? '40px' : '48px' }"
            elevation="0"
            prepend-icon="mdi-content-save-outline"
            label="Save"
            :readonly="!enabledButton"
            @click="handleSaveReport"
          >
          {{ t(label.AddNewPage.Save.title) }}</v-btn
          >
        </div>
      </WidthScrollContainer>
    </div>

    <section
      class="d-flex flex-column flex-grow-1 w-100 mx-auto px-4 justify-center"
      style="max-width: 600px"
    >
      <div class="ga-1 mb-6" style="display: flex; flex-direction: column">
        <h5 class="text-h6 text-cs_on_surface_variant">{{ t(label.AddNewPage.information) }}</h5>
        <v-card class="w-100 pa-4 rounded-xl" :class="className" elevation="1">
          <v-text-field
            v-model="templateName"
            class="mt-6"
            variant="outlined"
            :label="t(label.AddNewPage.name)"
            :rules="[
              inputTemplateNameReturnMessageRules.required,
              inputTemplateNameReturnMessageRules.maxLength,
            ]"
          ></v-text-field>
        </v-card>
      </div>

      <div class="ga-1 mb-6" style="display: flex; flex-direction: column">
        <h5 class="text-h6 text-cs_on_surface_variant">{{ t(label.AddNewPage.Template.title) }}</h5>
        <v-card class="pa-4 rounded-xl" :class="className" elevation="1">
          <ul style="list-style: none" class="d-flex flex-column ga-4 mt-6">
            <CardItemIMG
              v-for="report in templateReports"
              :key="report.baseReportId"
              @click="handleSelectTemplateReport(report)"
              :title="t(templateContent[report.tag as keyof typeof templateContent].title)"
              :subtitle="t(templateContent[report.tag as keyof typeof templateContent].description)"
              :img="{ src: templateContent[report.tag as keyof typeof templateContent].src }"
              :active="report.selected"
            />
          </ul>
        </v-card>
      </div>
    </section>
  </main>

  <v-dialog v-model="modalDiscard" width="auto">
    <DiscardModal
      v-if="modalDiscard"
      @cancel="modalDiscard = false"
      @confirm="handleDiscardNewReport"
    ></DiscardModal>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  computed,
  CSSProperties,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import {
  onBeforeRouteLeave,
  RouteRecordNameGeneric,
  useRouter,
} from "vue-router";
import { useDisplay, useTheme } from "vuetify";
import { VBtn } from "vuetify/lib/components/index.mjs";

import imgHeroLight from "@/assets/backgrounds/BG-nexus-light.jpg";
import imgHeroDark from "@/assets/backgrounds/BG-nexus-dark.jpg";
import imgClearsale from "@/assets/logo_mobile.png";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import WidthScrollContainer from "@/components/shared/WidthScrollContainer/WidthScrollContainer.vue";
import CardItemIMG from "@/components/nexus/CardItemIMG/CardItemIMG.vue";
import {
  postCreateReport,
  getListBaseReport,
  TemplateReports,
} from "@/api/resources/ReportsResource/nexusResource";

import BaseReportIMG from "@/assets/templates/base_report.png";
import BlankReportIMG from "@/assets/templates/blank_report.png";
import DiscardModal from "@/components/nexus/DiscardModal/DiscardModal.vue";
import { routeNames } from "@/router";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const { mdAndUp } = useDisplay();

interface TemplateReportsComponent extends TemplateReports {
  selected: boolean;
}

const templateName = ref("");
const templateReports = ref<TemplateReportsComponent[]>([]);
const enabledButton = ref(false);
const saveButtonHasClicked = ref(false);
const modalDiscard = ref(false);
const buttonDiscardHasClicked = ref(false);
const redirectRouterToDiscard = ref<RouteRecordNameGeneric>("");
const canAddNewReport = ref(true);

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const { name: themeName } = useTheme();
const className = useClassByTheme(classTheme);

const imgHero = computed(() => {
  return themeName.value === "light" ? imgHeroLight : imgHeroDark;
});

const inputTemplateNameRules = {
  required: (inputValue: string) => !!inputValue,
  maxLength: (inputValue: string) => inputValue && inputValue.length <= 50,
};
const inputTemplateNameReturnMessageRules = {
  required: (inputValue: string) => !!inputValue || "Name is required",
  maxLength: (inputValue: string) =>
    (inputValue && inputValue.length <= 50) ||
    "Name must be less than 50 characters",
};

const templateContent = {
  "Base report": {
    src: BaseReportIMG,
    title: label.AddNewPage.Template.BaseReport.title,
    description: label.AddNewPage.Template.BaseReport.content,
  },
  "Blank report": {
    src: BlankReportIMG,
    title: label.AddNewPage.Template.BlankReport.title,
    description: label.AddNewPage.Template.BlankReport.content,
  },
};

const getReportSelected = computed(() => {
  return templateReports.value.filter((report) => report.selected);
});

const hasModifiedForm = computed(() => {
  const hasOneSelected = templateReports.value.some(
    (report) => report.selected
  );
  return (
    hasOneSelected ||
    (inputTemplateNameRules.required(templateName.value) &&
      inputTemplateNameRules.maxLength(templateName.value))
  );
});

const hasValidateForm = computed(() => {
  const hasOneSelected = templateReports.value.some(
    (report) => report.selected
  );
  return (
    hasOneSelected &&
    inputTemplateNameRules.required(templateName.value) &&
    inputTemplateNameRules.maxLength(templateName.value)
  );
});

function handleBackButton() {
  if (!hasModifiedForm.value) {
    router.back();
    return;
  }

  redirectRouterToDiscard.value = routeNames.reports;
  modalDiscard.value = true;
}

function handleSelectTemplateReport(report: TemplateReports): void {
  templateReports.value = templateReports.value.map((reportMap) => {
    if (reportMap.baseReportId === report.baseReportId) {
      return { ...reportMap, selected: true };
    }
    return { ...reportMap, selected: false };
  });
}

const scrollContainerWidth = 40;
const refIcon = ref<VBtn | undefined>(undefined);
let originalIconDomRect: DOMRect | undefined = undefined;
const elementOnTop = ref(false);
const styleObj = reactive<CSSProperties>({
  transition: "all ease-in-out 0.3s",
});

function handleScrollOnTop(isOnTop: boolean): void {
  if (isOnTop) {
    elementOnTop.value = true;
    styleObj.height = `${scrollContainerWidth}px`;
    styleObj.width = `${scrollContainerWidth}px`;
  } else {
    elementOnTop.value = false;
    if (originalIconDomRect) {
      styleObj.height = `${originalIconDomRect.height}px`;
      styleObj.width = `${originalIconDomRect.width}px`;
    }
  }
}

function handleSaveReport() {
  postCreateReport({
    name: templateName.value,
    baseReportId: getReportSelected.value[0].baseReportId,
  }).then((r) => {
    saveButtonHasClicked.value = true;
    router.push({
      name: routeNames.editReport,
      params: { id: r.reportId },
    });
  });
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
};

function handleDiscardNewReport() {
  buttonDiscardHasClicked.value = true;
  modalDiscard.value = false;
  router.push({
    name: redirectRouterToDiscard.value,
  });
}

onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

onMounted(() => {
  getListBaseReport().then((response) => {
    canAddNewReport.value = response.canAddNewReport;

    if (response.results.length === 0) {
      router.replace({
        name: routeNames.reports,
      });
      return;
    }

    templateReports.value = response.results.map((report) => ({
      ...report,
      selected: false,
    }));

    if (!canAddNewReport.value) {
      router.replace({
        name: routeNames.reports,
      });
    }
  });
});

watch(
  [templateName, templateReports],
  () => {
    if (hasValidateForm.value) {
      enabledButton.value = true;
      return;
    }
    enabledButton.value = false;
  },
  { deep: true, immediate: true }
);

onBeforeRouteLeave(async (to, from, next) => {
  redirectRouterToDiscard.value = to.name;

  if (hasModifiedForm.value) {
    if (
      buttonDiscardHasClicked.value ||
      (saveButtonHasClicked.value && hasValidateForm.value)
    ) {
      next();
      return;
    }
    modalDiscard.value = true;
    next(false);
    return;
  }
  next();
});
</script>

<style scoped>
.save-button {
  text-transform: none;
  border-radius: 0.75rem;
  padding: 0.62rem 1.5rem 0.62rem 1rem;
  height: 3.5rem;
}
</style>
