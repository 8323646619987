import { useLocalStorage } from "@/utils/localstorage/localstorage";

export const BASE_URL = process.env.VUE_APP_BASE_URL_API ?? "";

interface ResponseApps {
  results: string[];
}

const { getStorage } = useLocalStorage();

export function getApps(): Promise<ResponseApps> {
  const token = JSON.parse(getStorage("token") ?? '{"access_token": ""}') as {
    access_token: string;
  };
  return fetch(BASE_URL + "/one/users/apps", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.access_token}`,
    },
  }).then((response) => response.json());
}
