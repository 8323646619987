import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-space-between align-center"
}
const _hoisted_2 = { class: "d-flex align-center ga-4" }
const _hoisted_3 = { class: "pb-7" }
const _hoisted_4 = { style: {"min-width":"210px"} }
const _hoisted_5 = { class: "pb-6" }
const _hoisted_6 = { class: "d-flex ga-2 text-cs_primary" }

import { routeNames } from "@/router/index";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useDisplay, useTheme } from "vuetify";
import {
  onBeforeRouteLeave,
  RouteRecordNameGeneric,
  useRoute,
  useRouter,
} from "vue-router";

import DiscardModal from "@/components/nexus/DiscardModal/DiscardModal.vue";
import ReportBI from "@/components/nexus/ReportBI/ReportBI.vue";
import { useReportsStore } from "@/store/ReportsStore";
import {
  getReportEmbed,
  patchReportEdit,
  ResponseReportEdit,
} from "@/api/resources/ReportsResource/nexusResource";
import { Embed } from "powerbi-client";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import { useLocalStorage } from "@/utils/localstorage/localstorage";

enum ViewMode {
  View = 0,
  Edit = 1,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NexusEditReportView',
  setup(__props) {

const { mdAndUp } = useDisplay();
const router = useRouter();
const route = useRoute();
const { $state, $patch } = useReportsStore();
const { name: themeName } = useTheme();
const { getStorage } = useLocalStorage();

const reportEdit = ref<ResponseReportEdit | undefined>(undefined);
const rotateSmartPhoneSnackbar = ref(false);

const inputTemplateNameReturnMessageRules = {
  required: (inputValue: string) => !!inputValue || "Name is required",
  maxLength: (inputValue: string) =>
    (inputValue && inputValue.length <= 50) ||
    "Name must be less than 50 characters",
};

const templateName = ref("");
const reportEmbed = ref<Embed | undefined>(undefined);
const templateHasModified = ref(false);
const enabledButton = ref(false);
const buttonDiscardHasClicked = ref(false);
const saveButtonHasClicked = ref(false);
const modalDiscard = ref(false);
const redirectRouterToDiscard = ref<RouteRecordNameGeneric>("");
const inputTemplateNameRules = {
  required: (inputValue: string) => !!inputValue,
  maxLength: (inputValue: string) => inputValue && inputValue.length <= 50,
};

const reportIdLoad =
  computed(() => route.params.id as string) &&
  process.env.VUE_APP_ENV !== "development"
    ? (route.params.id as string)
    : getStorage("report-id") ?? "1711d2b9-6375-40bd-b273-22e64d9b4fa7";

const hasModifiedForm = computed(() => {
  return templateHasModified.value || hasModifedInput.value;
});

const hasValidateForm = computed(() => {
  return (
    inputTemplateNameRules.required(templateName.value) &&
    inputTemplateNameRules.maxLength(templateName.value)
  );
});

const onEvents = [
  "selectionChanged",
  "swipeEnd",
  "filtersApplied",
  "visualClicked",
  "pageChanged",
  "dataHyperlinkClicked",
  "dataSelected",
  "buttonClicked",
  "blur",
];

function onReportLoaded(report: Embed) {
  reportEmbed.value = report;
  reportEmbed.value.on("saved", () => {
    patchReportEdit({ name: templateName.value, reportId: reportIdLoad }).then(
      () => {
        templateHasModified.value = false;
      }
    );
  });
  reportEmbed.value.on("blur", () => {
    templateHasModified.value = true;
  });

  onEvents.forEach((event) => {
    if (reportEmbed.value)
      reportEmbed.value.on(event, () => {
        templateHasModified.value = true;
      });
  });
}

function handleSaveReport() {
  if (reportEmbed.value) reportEmbed.value.save();
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  if (hasModifiedForm.value) event.preventDefault();
};

function handleDiscardNewReport() {
  buttonDiscardHasClicked.value = true;
  modalDiscard.value = false;
  router.push({
    name: redirectRouterToDiscard.value?.toString().includes(routeNames.reports)
      ? redirectRouterToDiscard.value
      : routeNames.viewReport,
  });
}

onMounted(() => {
  rotateSmartPhoneSnackbar.value = true;
  window.addEventListener("beforeunload", handleBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);

  if (reportEmbed.value) {
    onEvents.forEach((event) => {
      reportEmbed.value?.off(event);
    });
  }
});

onMounted(() => {
  if (route.params.id as string) {
    getReportEmbed(route.params.id as string).then((response) => {
      reportEdit.value = response;
      templateName.value = response.reportName;
    });
    return;
  }
  router.push({
    name: routeNames.reports
  });
});

const hasModifedInput = ref(false);

watch(
  templateName,
  (newValue, oldValue) => {
    if (oldValue && oldValue.length > 0 && newValue !== oldValue) {
      hasModifedInput.value = true;
    }
    if (hasValidateForm.value) {
      enabledButton.value = true;
      return;
    }
    enabledButton.value = false;
  },
  { deep: true, immediate: true }
);

onBeforeRouteLeave(async (to, from, next) => {
  redirectRouterToDiscard.value = to.name;

  if (hasModifiedForm.value) {
    if (
      buttonDiscardHasClicked.value ||
      (saveButtonHasClicked.value && hasValidateForm.value)
    ) {
      next();
      return;
    }
    modalDiscard.value = true;
    next(false);
    return;
  }
  next();
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (reportEdit.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["d-flex flex-column ga-4", _unref(mdAndUp) || 'px-2'])
        }, [
          _createElementVNode("div", {
            class: "w-full bg-cs_secondary_container rounded-xl",
            style: _normalizeStyle({
        height: _unref(mdAndUp) || !_unref($state).isLandScape ? '520px' : '100vh',
        borderRadius: '16px',
        overflow: 'hidden',
      })
          }, [
            (reportEdit.value.embedToken.token)
              ? (_openBlock(), _createBlock(ReportBI, {
                  key: 0,
                  mode: ViewMode.Edit,
                  report: reportEdit.value,
                  "report-id": _unref(reportIdLoad),
                  onLoaded: onReportLoaded
                }, null, 8, ["mode", "report", "report-id"]))
              : _createCommentVNode("", true)
          ], 4),
          (_unref(mdAndUp) || !_unref($state).isLandScape)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_v_btn, {
                      color: "cs_surface_container",
                      icon: "mdi-arrow-left",
                      size: "40px",
                      onClick: _cache[0] || (_cache[0] = 
              () => {
                if (hasModifiedForm.value) {
                  redirectRouterToDiscard.value = _unref(routeNames).reports;
                  modalDiscard.value = true;
                }

                _unref(router).back();
              }
            ),
                      "data-testid": "back-button"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_v_text_field, {
                      modelValue: templateName.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((templateName).value = $event)),
                      style: {"width":"100%"},
                      label: "Name",
                      variant: "outlined",
                      rules: [
              inputTemplateNameReturnMessageRules.required,
              inputTemplateNameReturnMessageRules.maxLength,
            ]
                    }, null, 8, ["modelValue", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_v_btn, {
                    color: _unref(themeName) === 'dark' ? 'surface' : 'surface-variant-bright',
                    disabled: !enabledButton.value,
                    onClick: handleSaveReport
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_v_icon, { icon: "mdi-content-save-outline" }),
                        _cache[6] || (_cache[6] = _createTextVNode(" Save "))
                      ])
                    ]),
                    _: 1
                  }, 8, ["color", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: modalDiscard.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((modalDiscard).value = $event)),
      width: "auto"
    }, {
      default: _withCtx(() => [
        (modalDiscard.value)
          ? (_openBlock(), _createBlock(DiscardModal, {
              key: 0,
              onCancel: _cache[2] || (_cache[2] = ($event: any) => (modalDiscard.value = false)),
              onConfirm: handleDiscardNewReport
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (!_unref(mdAndUp) && !_unref($state).isLandScape)
      ? (_openBlock(), _createBlock(SnackbarClose, {
          key: 1,
          modelValue: rotateSmartPhoneSnackbar.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((rotateSmartPhoneSnackbar).value = $event)),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (rotateSmartPhoneSnackbar.value = false))
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("span", { class: "text-white" }, " Rotate you device for full screen view ", -1)
          ])),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})