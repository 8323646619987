<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        data-testid="card"
        @click="$emit('click')"
        elevation="0"
        v-bind="props"
        :class="[
          className,
          isHovering && 'bg-cs_ref_palette_neutral99',
          active && 'bg-cs_surface_container',
        ]"
        class="d-flex ga-4 rounded-xl ga-4 pa-2 cursor-pointer"
      >
        <div>
          <v-img
            class="rounded-xl mx-auto"
            :lazy-src="img?.src ?? 'https://picsum.photos/id/237/120/80'"
            :src="img?.src ?? 'https://picsum.photos/id/237/120/80'"
            :alt="img?.alt ?? 'picsum'"
            :width="width ?? '120px'"
            :height="height ?? '80px'"
          />
        </div>
        <div class="py-4">
          <slot>
            <h6
              class="text-cs_on_surface_variant"
              :style="`font-size: ${fontSize}`"
            >
              {{ title }}
            </h6>
            <p
              class="text-cs_on_surface_variant"
              :style="`font-size: ${fontSize}`"
            >
              {{ subtitle }}
            </p>
          </slot>
        </div>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup lang="ts" generic="T">
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { tokens } from "@clearsale/tokens-ds";

const fontSize = tokens.typography["typescale-label-large-size"];

defineProps<{
  img?: { src?: string; alt?: string };
  title?: string;
  subtitle?: string;
  height?: string;
  width?: string;
  active?: boolean;
}>();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

defineEmits(["click"]);

const className = useClassByTheme(classTheme);
</script>
