<template>
  <header v-if="shouldRenderHeader()">
    <div
      class="d-flex align-center ga-8 w-100 ma-auto px-4"
      style="position: relative"
      id="menu"
    >
      <slot name="logo" v-if="showLogo">
        <img
          :src="logoNexus"
          alt="Clearsale Logo"
          class="logo__img"
          height="48px"
          @click="handleLogoClick"
        />
      </slot>
      <div class="d-flex align-center justify-space-between w-100">
        <div>
          <slot> <div></div> </slot>
        </div>
        <nav>
          <slot name="nav">
            <div class="d-flex align-center">
              <slot name="links">
                <AppsMenu v-model="appsMenu" />
                <UserMenu v-model="userMenu" />
              </slot>
            </div>
          </slot>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import imgClearsale from "@/assets/logo_mobile.png";
import logoNexus from "@/assets/logo_nexus.svg";
import UserMenu from "./UserMenu/UserMenu.vue";
import AppsMenu from "./AppsMenu/AppsMenu.vue";
import { ref, watch } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useReportsStore } from "@/store/ReportsStore";
import { hidableHeaderRoutes, routeNames } from "@/router";
import { useRoute, useRouter } from "vue-router";

withDefaults(defineProps<{ showLogo?: boolean }>(), { showLogo: true });
const appsMenu = ref(false);
const userMenu = ref(false);
const { mdAndUp } = useDisplay();
const route = useRoute();
const router = useRouter();
const { $state } = useReportsStore();

const handleLogoClick = () => {
  if (window.scrollY > 0) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    router.push({ name: routeNames.reports });
  }
};

function shouldRenderHeader(): boolean {
  return (
    mdAndUp.value ||
    !$state.isLandScape ||
    !hidableHeaderRoutes.includes(route.name as string)
  );
}

watch(appsMenu, () => {
  if (appsMenu.value) {
    userMenu.value = false;
  }
});

watch(userMenu, () => {
  if (userMenu.value) {
    appsMenu.value = false;
  }
});
</script>

<style lang="scss" scoped>
.logo__img:hover {
  cursor: pointer;
}
</style>
