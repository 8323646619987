import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Teleport as _Teleport, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cs__overlay"
}
const _hoisted_2 = {
  key: 0,
  class: "cs__menu d-flex flex-column flex-wrap ga-2 bg-surface-variant",
  style: {"border-radius":"19px 0px 0px 16px","z-index":"9999"}
}
const _hoisted_3 = {
  class: "d-flex justify-end px-4 ga-4 cursor-pointer",
  style: {"min-width":"216px"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "mt-2 text-on-surface-variant",
  style: {"font-size":"14px","font-weight":"normal"}
}

import { ref } from "vue";
import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import imgClearsale from "@/assets/logo_mobile.png";
import { appsExample } from "../constants/AppsLinks";
import { useCustomMenu } from "../useCustomMenu";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppsMenuMobile',
  props: /*@__PURE__*/_mergeModels({
    links: { default: () => appsExample }
  }, {
    "modelValue": { default: false },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["routerTo", "selectLink"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const dataAppsList = ref(props.links);

const modelMenu = _useModel(__props, "modelValue");

const { handleRouterTo, handleCloseMenu } = useCustomMenu({
  emits,
  props,
  model: modelMenu,
});

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      icon: "",
      variant: "text",
      color: "cs_surface_variant",
      id: "menu-activator",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (modelMenu.value = !modelMenu.value))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          icon: "mdi-apps",
          class: "text-cs_on_surface_variant"
        })
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (modelMenu.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "open" }, {
        default: _withCtx(() => [
          (modelMenu.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    class: "mt-2",
                    style: {"font-size":"14px","font-weight":"normal"},
                    color: "on-surface-variant",
                    variant: "text"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        icon: "mdi-menu-open",
                        size: "24"
                      })
                    ]),
                    _: 1
                  })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataAppsList.value, (app) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: app.label,
                    class: _normalizeClass(["d-flex pl-8 pa-3 ga-4 cursor-pointer", `order-${app.order}`]),
                    style: {"min-width":"216px"},
                    onClick: ($event: any) => (_unref(handleRouterTo)(app))
                  }, [
                    _createElementVNode("img", {
                      src: _unref(imgClearsale),
                      alt: "Clearsale Logo",
                      height: "32px"
                    }, null, 8, _hoisted_5),
                    _createElementVNode("h4", _hoisted_6, _toDisplayString(app.label), 1)
                  ], 10, _hoisted_4))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ])), [
    [_unref(vClickOutside), _unref(handleCloseMenu)]
  ])
}
}

})