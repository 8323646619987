import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useClassByTheme } from "@/components/hooks/useClassByTheme";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardCircle',
  setup(__props) {

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);

return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: _normalizeClass(["rounded-circle d-flex justify-center align-center cursor-pointer", _unref(className)]),
    style: {"width":"240px","height":"240px"}
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})