import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background-color":"white","min-height":"100vh","display":"flex","flex-direction":"column"}
}
const _hoisted_2 = {
  style: {"max-width":"1040px"},
  class: "w-100 mx-auto"
}

import "@/plugins/gsap";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import { validateSession } from "@/services/auth";
import { useLocalStorage } from "@/utils/localstorage/localstorage";
import { onMounted, onUnmounted, ref } from "vue";
import { useReportsStore } from "./store/ReportsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { $patch } = useReportsStore();
const { getStorage } = useLocalStorage();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const showApp = ref(false);

const className = useClassByTheme(classTheme);

const activeSSO = process.env.VUE_APP_ENV !== "development";

addEventListener("access-token", () => {
  showApp.value = true;
});

const updateOrientation = () => {
  const angleDevice = screen.orientation.angle;

  if (angleDevice === 0) {
    $patch({ isLandScape: false });
    return;
  }

  $patch({ isLandScape: true });
};

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
  screen.orientation.addEventListener("change", updateOrientation);
});

onUnmounted(() => {
  screen.orientation.removeEventListener("change", updateOrientation);
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (showApp.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_theme_provider, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_unref(className)),
              style: {"flex":"1","display":"flex","flex-direction":"column"}
            }, [
              _createVNode(OneMenu, { class: "my-4" }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_RouterView)
              ])
            ], 2)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})