<template>
  <div
    style="
      background-color: white;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    "
    v-if="showApp"
  >
    <v-theme-provider>
      <div
        :class="className"
        style="flex: 1; display: flex; flex-direction: column"
      >
        <OneMenu class="my-4"></OneMenu>
        <div style="max-width: 1040px" class="w-100 mx-auto">
          <RouterView />
        </div>
      </div>
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import "@/plugins/gsap";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import { validateSession } from "@/services/auth";
import { useLocalStorage } from "@/utils/localstorage/localstorage";
import { onMounted, onUnmounted, ref } from "vue";
import { useReportsStore } from "./store/ReportsStore";

const { $patch } = useReportsStore();
const { getStorage } = useLocalStorage();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const showApp = ref(false);

const className = useClassByTheme(classTheme);

const activeSSO = process.env.VUE_APP_ENV !== "development";

addEventListener("access-token", () => {
  showApp.value = true;
});

const updateOrientation = () => {
  const angleDevice = screen.orientation.angle;

  if (angleDevice === 0) {
    $patch({ isLandScape: false });
    return;
  }

  $patch({ isLandScape: true });
};

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
  screen.orientation.addEventListener("change", updateOrientation);
});

onUnmounted(() => {
  screen.orientation.removeEventListener("change", updateOrientation);
});
</script>
