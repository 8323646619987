import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'SnackbarClose',
  props: {
    "modelValue": { default: false },
    "modelModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["close"], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

const model = _useModel(__props, "modelValue");
const emits = __emit;

function handleCloseSnackbar() {
  emits("close");
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    color: "inverse-surface"
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "white",
        variant: "text",
        onClick: handleCloseSnackbar,
        icon: "mdi-close"
      })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-white" }, " File limit reached. Consider deleting to add more. ", -1))
      ])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}
}

})