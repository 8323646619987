import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_2 = { class: "d-flex flex-column justify-center align-center text-center" }
const _hoisted_3 = { class: "mt-5" }

import { routeNames } from "@/router/index";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay, useTheme } from "vuetify";

import imgHeroLight from "@/assets/backgrounds/BG-nexus-light.jpg";
import imgHeroDark from "@/assets/backgrounds/BG-nexus-dark.jpg";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import CardCircle from "@/components/nexus/CardCircle/CardCircle.vue";
import CardItemReport from "@/components/nexus/CardItem/CardItemReport.vue";
import AddButton from "@/components/shared/AddButton/AddButton.vue";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import DeveloperMode from "@/components/nexus/DeveloperMode/DeveloperMode.vue";

import {
  Report,
  RequestReport,
  deleteReportAPI,
  getReports,
} from "@/api/resources/ReportsResource/nexusResource";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import { useReportsStore } from "@/store/ReportsStore";
import { vTripleTouch } from "@/directives/doubleTouch";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'NexusView',
  setup(__props) {

const { mdAndUp } = useDisplay();
const reportStore = useReportsStore();
const router = useRouter();
const { name: theme } = useTheme();

const { t } = useI18n();

const messageLimitSnackbar = ref(false);
const dialogDeveloperMode = ref(false);
const canAddNewReport = ref(true);

const loading = ref(false);
const finished = ref(false);

const imgHero = computed(() => {
  return theme.value === "light" ? imgHeroLight : imgHeroDark;
});

const classTheme = {
  light: "cs_on_primary",
  dark: "cs_ref_palette_primary20",
};
const className = useClassByTheme(classTheme);

const hasEmptyReports = computed(() => reportStore.$state.reports.length < 1);

function handleTripleTouch() {
  if (process.env.VUE_APP_ENV === "development")
    dialogDeveloperMode.value = true;
}

function handleDeleteReport(report: Report) {
  deleteReportAPI(report.id).then(() => {
    reportStore.removeReport(report);
    canAddNewReport.value = true;
  });
}

function handleEditReport(report: Report) {
  router.push({ name: routeNames.editReport, params: { id: report.id } });
}

function handleSelectReport(report: Report) {
  router.push({ name: routeNames.viewReport, params: { id: report.id } });
}

function handleAddReport() {
  if (!canAddNewReport.value) {
    messageLimitSnackbar.value = true;
    return;
  }
  messageLimitSnackbar.value = false;
  router.push({ name: routeNames.newReport });
}

// Metodo relacionado a paginação importado do shield
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function fetchCopyReportList() {
  loading.value = true;
  getReports({
    filters: reportStore.$state.filters,
    pagging: reportStore.$state.pagging,
  } as unknown as RequestReport)
    .then((data) => {
      if (finished.value) return;

      const copyReportData = structuredClone(reportStore.$state.reports);

      copyReportData.push(
        ...data.results.map((report) => ({
          ...report,
          selected: false,
        }))
      );
      reportStore.updateAllReports(copyReportData);

      canAddNewReport.value = data.canAddNewReport;

      finished.value =
        data.results.length < reportStore.$state.pagging.limit ||
        data.results.length === 0;
    })
    .finally(() => {
      loading.value = false;
    });
}

async function fetchReportList() {
  loading.value = true;
  getReports({
    filters: reportStore.$state.filters,
    pagging: reportStore.$state.pagging,
  } as unknown as RequestReport)
    .then((data) => {
      reportStore.updateAllReports(data.results);
      canAddNewReport.value = data.canAddNewReport;

      finished.value =
        data.results.length < reportStore.$state.pagging.limit ||
        data.results.length === 0;
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(() => {
  reportStore.$patch({
    filters: {
      ids: [],
      ownerIds: [],
      updatedAtFrom: null,
      updatedAtTo: null,
    },
  });
  fetchReportList();
});

// Metodo relacionado a filtragem importado do shield
// watch(
//   () => reportStore.$state.filters,
//   async () => {
//     fetchReportList();
//   },
//   {
//     deep: true,
//   }
// );

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_unref(mdAndUp) || 'px-4'),
        style: _normalizeStyle({ height: _unref(mdAndUp) ? '220px' : '120px' })
      }, [
        _createVNode(CustomHero, {
          label: _unref(label).ReportsPage.title,
          "src-image": imgHero.value
        }, null, 8, ["label", "src-image"])
      ], 6)), [
        [_unref(vTripleTouch), handleTripleTouch]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex justify-space-between mt-2 mb-4", _unref(mdAndUp) || 'px-4'])
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-100" }, null, -1)),
        _createVNode(AddButton, {
          label: _unref(t)(_unref(label).ReportsPage.AddReport.title),
          onClick: handleAddReport
        }, null, 8, ["label"])
      ], 2),
      (!loading.value)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: _normalizeClass(["d-flex justify-center flex-grow-1", hasEmptyReports.value ? 'align-center' : ''])
          }, [
            (hasEmptyReports.value)
              ? (_openBlock(), _createBlock(CardCircle, {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = 
          () => {
            _unref(router).push({ name: _unref(routeNames).newReport });
          }
        )
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_v_btn, {
                        icon: "",
                        width: "40",
                        height: "40"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            color: _unref(className),
                            size: "30",
                            icon: "mdi-plus"
                          }, null, 8, ["color"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(_unref(mdAndUp) ? _unref(label).ReportsPage.AddReportDesktop.title : _unref(label).ReportsPage.AddReportMobile.title)), 1)
                    ])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  ref: "transactionListRef",
                  style: {"flex":"1"},
                  class: _normalizeClass(["d-flex flex-column ga-2", _unref(mdAndUp) || 'px-4'])
                }, [
                  _createVNode(CardItemReport, {
                    reports: _unref(reportStore).$state.reports,
                    onDelete: handleDeleteReport,
                    onEdit: handleEditReport,
                    onSelect: handleSelectReport
                  }, null, 8, ["reports"])
                ], 2))
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createVNode(SnackbarClose, {
      modelValue: messageLimitSnackbar.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((messageLimitSnackbar).value = $event)),
      onClose: _cache[2] || (_cache[2] = ($event: any) => (messageLimitSnackbar.value = false))
    }, null, 8, ["modelValue"]),
    _createVNode(DeveloperMode, {
      dialog: dialogDeveloperMode.value,
      "onUpdate:dialog": _cache[3] || (_cache[3] = ($event: any) => ((dialogDeveloperMode).value = $event))
    }, null, 8, ["dialog"])
  ], 64))
}
}

})