<template>
  <div v-click-outside="handleCloseMenu">
    <v-btn
      icon
      variant="text"
      color="cs_surface_variant"
      id="menu-activator"
      @click="modelMenu = !modelMenu"
    >
      <v-icon icon="mdi-apps" class="text-cs_on_surface_variant"></v-icon>
    </v-btn>
    <Transition name="fade">
      <div
        v-if="modelMenu"
        class="cs__menu d-flex flex-wrap ga-3 bg-surface-variant rounded-lg"
        :class="[alignPerAppsClass]"
      >
        <template v-for="app in links" :key="app.name">
          <div
            class="d-flex flex-column justify-center align-center pa-4 cursor-pointer"
            :class="`order-${app.order}`"
            style="min-width: 90px"
            @click="handleRouterTo(app)"
          >
            <img :src="imgClearsale" alt="Clearsale Logo" height="32px" />

            <h4
              class="mt-2 text-on-surface-variant"
              style="font-size: 14px; font-weight: normal"
            >
              {{ app.label }}
            </h4>
          </div>
        </template>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import imgClearsale from "@/assets/logo_mobile.png";
import { appsExample } from "../constants/AppsLinks";
import { useCustomMenu } from "../useCustomMenu";

const props = withDefaults(defineProps<{ links: NewMenu.Link[] }>(), {
  links: () => appsExample,
});

const emits = defineEmits<{
  (e: "routerTo", routeApp: NewMenu.Link): void;
  (e: "selectLink", link: NewMenu.Link): void;
}>();

const modelMenu = defineModel({ default: false });

const alignPerAppsClass = computed(() => {
  return props.links.length > 3
    ? props.links.length % 3 === 0
      ? "justify-center"
      : "justify-start"
    : "justify-end";
});

const { handleRouterTo, handleCloseMenu } = useCustomMenu({
  emits,
  props,
  model: modelMenu,
});
</script>

<style scoped lang="scss">
.cs__menu {
  max-width: 318px;
  position: absolute;
  z-index: 999;
  right: 16px;
  top: 48px;
  transition: opacity 0.3s;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
