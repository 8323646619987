<template>
  <div v-click-outside="handleCloseMenu">
    <v-btn
      icon
      variant="text"
      color="cs_surface_variant"
      @click.stop="modelMenu = !modelMenu"
    >
      <v-icon
        icon="mdi-account-circle"
        class="text-cs_on_surface_variant"
      ></v-icon>
    </v-btn>
    <Teleport to="body">
      <Transition name="fade">
        <div v-if="modelMenu" class="cs__overlay"></div>
      </Transition>
      <Transition name="open">
        <div
          v-if="modelMenu"
          class="cs__menu d-flex flex-column flex-wrap ga-2 bg-surface-variant"
          style="border-radius: 19px 0px 0px 16px; z-index: 9999"
        >
          <div
            class="d-flex flex-column overflow-y-auto"
            style="
              min-width: 240px;
              border-radius: 19px 0px 0px 0px;
              height: 100%;
            "
          >
            <slot>
              <template v-for="link in renderCurrentLinks" :key="link.label">
                <slot
                  v-if="hero && link.order && link.order === 1"
                  name="hero"
                  classStyle="pa-4 bg-cs_secondary_container cursor-pointer"
                >
                  <div
                    @click.stop="handleRouterTo(link)"
                    class="pa-4 bg-cs_secondary_container cursor-pointer d-flex align-center justify-space-between"
                    :class="`order-${link.order}`"
                  >
                    <div class="d-flex align-center">
                      <div v-if="currentLinksRef.length > 0">
                        <v-icon icon="mdi-menu-left"></v-icon>
                      </div>
                      <div
                        class="d-flex align-center ga-4"
                        :class="link.className"
                      >
                        <img
                          :src="link.img.name"
                          alt="img"
                          :width="link.img.size"
                          :height="link.img.size"
                          v-if="link.img"
                        />
                        <v-icon
                          v-else-if="link.icon"
                          :icon="link.icon.name"
                          :size="link.icon.size"
                        ></v-icon>
                        <div>
                          {{ link.label }}
                        </div>
                      </div>
                    </div>
                    <div v-if="link.subLinks.length">
                      <v-icon icon="mdi-menu-right"></v-icon>
                    </div>
                  </div>
                </slot>
                <v-divider
                  v-else-if="link.id === 'separator'"
                  :class="`order-${link.order}`"
                  :thickness="2"
                ></v-divider>
                <div
                  v-else-if="link.id === 'space'"
                  :class="`order-${link.order}`"
                  style="flex: 1"
                ></div>
                <slot
                  name="items"
                  :links="renderCurrentLinks"
                  :link="link"
                  classStyle="pa-4 bg-surface-variant  cursor-pointer"
                  v-else
                >
                  <div
                    @click.stop="handleRouterTo(link)"
                    class="pa-4 bg-surface-variant cursor-pointer d-flex align-center"
                    :class="`order-${link.order}`"
                  >
                    <div
                      class="d-flex align-center justify-space-between w-100"
                      :class="link.className"
                    >
                      <div class="d-flex align-center ga-2">
                        <v-icon
                          v-if="link.icon"
                          :icon="link.icon ? link.icon.name : ''"
                          :size="link.icon ? link.icon.size : '24px'"
                        ></v-icon>
                        <div class="d-flex align-center">
                          {{ link.label }}
                        </div>
                      </div>
                      <div v-if="link.subLinks.length">
                        <v-icon icon="mdi-menu-right"></v-icon>
                      </div>
                    </div>
                  </div>
                </slot>
              </template>
            </slot>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { vClickOutside } from "@/components/one/NewMenu/directives/clickOutside";
import { linksExample } from "../constants/Menu";
import { useCustomMenu } from "../useCustomMenu";

const props = withDefaults(
  defineProps<{
    links: NewMenu.Link[];
    hero: boolean;
  }>(),
  {
    links: () => linksExample,
    hero: true,
  }
);

const emits = defineEmits<{
  (e: "routerTo", routeApp: NewMenu.Link): void;
  (e: "selectLink", link: NewMenu.Link): void;
}>();

const modelMenu = defineModel({ default: false });

const { currentLinksRef, handleCloseMenu, handleRouterTo, renderCurrentLinks } =
  useCustomMenu({ props, emits, model: modelMenu });
</script>

<style scoped>
.cs__menu {
  max-width: 318px;
  height: 100vh;
  position: fixed;
  z-index: 999;

  top: 0px;
  transition: all 0.3s;
  opacity: 1;
  right: 0px;
}

.cs__overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background: rgb(var(--v-theme-scrim));
  opacity: 0.5;
}

.open-enter-active,
.open-leave-active {
  transition: all 0.5s ease;
}

.open-enter-from,
.open-leave-to {
  right: -318px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
