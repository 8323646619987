import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_2 = { class: "d-flex flex-column justify-center align-center text-center" }
const _hoisted_3 = { class: "d-flex justify-center align-center py-2" }
const _hoisted_4 = ["src", "alt"]

import ButtonFilterNameMobile from "@/components/nexus/ButtonFilterNameMobile/ButtonFilterNameMobile.vue";
import ButtonFilterMobile from "@/components/shared/ButtonFilterMobile/ButtonFilterMobile.vue";
import ContainerSwiper from "@/components/shared/ContainerSwiper/ContainerSwiper.vue";
import { routeNames } from "@/router/index";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify";

import imgHero from "@/assets/backgrounds/transaction-desktop-light.png";
import imgClearsale from "@/assets/logo_mobile.png";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import CardCircle from "@/components/nexus/CardCircle/CardCircle.vue";
import CardItemReport from "@/components/nexus/CardItem/CardItemReport.vue";
import AddButton from "@/components/shared/AddButton/AddButton.vue";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import DeveloperMode from "@/components/nexus/DeveloperMode/DeveloperMode.vue";

import {
  Report,
  RequestReport,
  deleteReportAPI,
  getReports,
} from "@/api/resources/ReportsResource/nexusResource";
import SnackbarClose from "@/components/nexus/SnackbarClose/SnackbarClose.vue";
import { useReportsStore } from "@/store/ReportsStore";
import { vTripleTouch } from "@/directives/doubleTouch";
import ButtonFilterTextMobile from "@/components/shared/ButtonFilterTextMobile/ButtonFilterTextMobile.vue";
import ButtonFilterOwnerMobile from "@/components/nexus/ButtonFilterOwnerMobile/ButtonFilterOwnerMobile.vue";
import ButtonFilterDateMobile from "@/components/nexus/ButtonFilterDateMobile/ButtonFilterDateMobile.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NexusView',
  setup(__props) {

const { mdAndUp } = useDisplay();
const reportStore = useReportsStore();
const router = useRouter();

const messageLimitSnackbar = ref(false);
const dialogDeveloperMode = ref(false);
const canAddNewReport = ref(true);

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});
const loading = ref(false);
const finished = ref(false);

const classTheme = {
  light: "cs_on_primary",
  dark: "cs_ref_palette_primary20",
};
const className = useClassByTheme(classTheme);

const hasEmptyReports = computed(() => reportStore.$state.reports.length < 1);

function handleTripleTouch() {
  if (process.env.VUE_APP_ENV === "development")
    dialogDeveloperMode.value = true;
}

function handleDeleteReport(report: Report) {
  deleteReportAPI(report.id).then(() => {
    reportStore.removeReport(report);
    canAddNewReport.value = true;
  });
}

function handleEditReport(report: Report) {
  router.push({ name: routeNames.editReport, params: { id: report.id } });
}

function handleSelectReport(report: Report) {
  router.push({ name: routeNames.viewReport, params: { id: report.id } });
}

function handleAddReport() {
  if (!canAddNewReport.value) {
    messageLimitSnackbar.value = true;
    return;
  }
  messageLimitSnackbar.value = false;
  router.push({ name: routeNames.newReport });
}

// Metodo relacionado a paginação importado do shield
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function fetchCopyReportList() {
  loading.value = true;
  getReports({
    filters: reportStore.$state.filters,
    pagging: reportStore.$state.pagging,
  } as unknown as RequestReport)
    .then((data) => {
      if (finished.value) return;

      const copyReportData = structuredClone(reportStore.$state.reports);

      copyReportData.push(
        ...data.results.map((report) => ({
          ...report,
          selected: false,
        }))
      );
      reportStore.updateAllReports(copyReportData);

      canAddNewReport.value = data.canAddNewReport;

      finished.value =
        data.results.length < reportStore.$state.pagging.limit ||
        data.results.length === 0;
    })
    .finally(() => {
      loading.value = false;
    });
}

async function fetchReportList() {
  loading.value = true;
  getReports({
    filters: reportStore.$state.filters,
    pagging: reportStore.$state.pagging,
  } as unknown as RequestReport)
    .then((data) => {
      reportStore.updateAllReports(data.results);
      canAddNewReport.value = data.canAddNewReport;

      finished.value =
        data.results.length < reportStore.$state.pagging.limit ||
        data.results.length === 0;
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(() => {
  reportStore.$patch({
    filters: {
      ids: [],
      ownerIds: [],
      updatedAtFrom: null,
      updatedAtTo: null,
    },
  });
  fetchReportList();
});

// Metodo relacionado a filtragem importado do shield
// watch(
//   () => reportStore.$state.filters,
//   async () => {
//     fetchReportList();
//   },
//   {
//     deep: true,
//   }
// );

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_unref(mdAndUp) || 'px-2'),
        style: _normalizeStyle({ height: _unref(mdAndUp) ? '220px' : '120px', } )
      }, [
        _createVNode(CustomHero, {
          label: "Nexus",
          "src-image": _unref(imgHero)
        }, null, 8, ["src-image"])
      ], 6)), [
        [_unref(vTripleTouch), handleTripleTouch]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex justify-space-between my-2", _unref(mdAndUp) ? 'px-4' : 'px-2'])
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-100" }, null, -1)),
        _createVNode(AddButton, {
          label: "Add",
          onClick: handleAddReport
        })
      ], 2),
      _createElementVNode("section", {
        class: _normalizeClass(["d-flex justify-center flex-grow-1", hasEmptyReports.value ? 'align-center' : ''])
      }, [
        (hasEmptyReports.value)
          ? (_openBlock(), _createBlock(CardCircle, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
          () => {
            _unref(router).push({ name: _unref(routeNames).newReport });
          }
        )
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    width: "40",
                    height: "40"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        color: _unref(className),
                        size: "30",
                        icon: "mdi-plus"
                      }, null, 8, ["color"])
                    ]),
                    _: 1
                  }),
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mt-5" }, [
                    _createTextVNode(" Click "),
                    _createElementVNode("span", { class: "text-primary font-weight-bold" }, "here"),
                    _createTextVNode(" to create your first dashboard ")
                  ], -1))
                ])
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              ref: "transactionListRef",
              style: {"flex":"1"},
              class: _normalizeClass(["d-flex flex-column ga-3", _unref(mdAndUp) || 'px-2'])
            }, [
              _createVNode(CardItemReport, {
                reports: _unref(reportStore).$state.reports,
                onDelete: handleDeleteReport,
                onEdit: handleEditReport,
                onSelect: handleSelectReport
              }, null, 8, ["reports"])
            ], 2))
      ], 2)
    ]),
    _createElementVNode("footer", _hoisted_3, [
      _createElementVNode("img", {
        style: {"width":"24px"},
        src: imgClearsaleOptions.value.src,
        alt: imgClearsaleOptions.value.alt
      }, null, 8, _hoisted_4)
    ]),
    _createVNode(SnackbarClose, {
      modelValue: messageLimitSnackbar.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((messageLimitSnackbar).value = $event)),
      onClose: _cache[2] || (_cache[2] = ($event: any) => (messageLimitSnackbar.value = false))
    }, null, 8, ["modelValue"]),
    _createVNode(DeveloperMode, {
      dialog: dialogDeveloperMode.value,
      "onUpdate:dialog": _cache[3] || (_cache[3] = ($event: any) => ((dialogDeveloperMode).value = $event))
    }, null, 8, ["dialog"])
  ], 64))
}
}

})