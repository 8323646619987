<template>
  <div v-if="!loading && links?.length">
    <AppsMenuDesktop
      v-if="mdAndUp"
      v-model="openAppsMenu"
      :links="links"
      @routerTo="handleSelectRouterTo"
    />
    <AppsMenuMobile
      v-else
      v-model="openAppsMenu"
      :links="links"
      @routerTo="handleSelectRouterTo"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import AppsMenuDesktop from "./AppsMenuDesktop.vue";
import AppsMenuMobile from "./AppsMenuMobile.vue";
import { getApps } from "@/api/resources/oneResource";

import NexusLogo from "@/assets/logos/logo_nexus.svg";
import ShieldLogo from "@/assets/logos/logo_shield.svg";

const { mdAndUp } = useDisplay();
const router = useRouter();

const model = defineModel({ default: false });
const openAppsMenu = ref(model);

const routerMap: AppsMenu.SelectRouter = {
  NexusView: "/nexus",
  ShieldView: "/shield",
  OneView: "/app",
};

function handleSelectRouterTo(link: NewMenu.Link) {
  handleDispathCustomEventChangeRoute(
    routerMap[link.routerName as AppsMenu.AppView_Type],
    link.label
  );
}

function handleDispathCustomEventChangeRoute(path: string, name?: string) {
  const event = new CustomEvent("changeRoute", {
    detail: {
      path,
      name,
    },
  });
  window.dispatchEvent(event);
}

const loading = ref(true);

const apps = [
  {
    id: "Apps",
    label: "One",
    routerName: "OneView",
    order: 1,
    subLinks: [],
  },
  {
    id: "Apps",
    label: "Shield",
    routerName: "ShieldView",
    order: 2,
    img: ShieldLogo,
    subLinks: [],
  },
  {
    id: "Apps",
    label: "Nexus",
    routerName: "NexusView",
    order: 3,
    img: NexusLogo,
    subLinks: [],
  },
];

const links = ref<NewMenu.Link[]>();

function loadAppsIsShow() {
  getApps()
    .then((res) => {
      links.value = apps.filter((app) => res.results.includes(app.label));
    })
    .finally(() => {
      loading.value = false;
    });
}

onMounted(() => {
  loadAppsIsShow();
});

router.beforeEach(() => {
  loadAppsIsShow();
});
</script>
