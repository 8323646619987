import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-6 d-flex justify-end" }


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteModal',
  emits: ["cancel", "confirm"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

function handleConfirm() {
  emits("confirm");
}
function handleCancel() {
  emits("cancel");
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    "max-width": "312",
    height: "200",
    class: "rounded-xl pa-4",
    elevation: "1"
  }, {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, [
        _createElementVNode("h2", { class: "text-subtitle" }, "Delete file"),
        _createElementVNode("p", { class: "mt-4 text-subtitle-2 text-cs_on_surface_variant" }, " Are you sure want to permanently delete this file? ")
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          height: "40px",
          text: "Cancel",
          variant: "text",
          class: "text-none text-cs_on_surface_variant",
          onClick: handleCancel
        }),
        _createVNode(_component_v_btn, {
          height: "40px",
          text: "Delete",
          variant: "text",
          class: "text-none text-cs_error",
          onClick: handleConfirm
        })
      ])
    ]),
    _: 1
  }))
}
}

})