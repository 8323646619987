<template>
  <v-card max-width="312" height="200" class="rounded-xl pa-4" elevation="1">
    <div>
      <h2 class="text-subtitle">{{ t(label.Modals.DeleteFile.title) }}</h2>
      <p class="mt-4 text-subtitle-2 text-cs_on_surface_variant">
      {{ t(label.Modals.DeleteFile.content) }}
      </p>
    </div>
    <div class="mt-6 d-flex justify-end">
      <v-btn
        height="40px"
        :text="t(label.Modals.DeleteFile.cancel)"
        variant="text"
        class="text-none text-cs_on_surface_variant"
        @click="handleCancel"
      ></v-btn>
      <v-btn
        height="40px"
        :text="t(label.Modals.DeleteFile.delete)"
        variant="text"
        class="text-none text-cs_error"
        @click="handleConfirm"
      ></v-btn>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { label } from '@/plugins/locales/global';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emits = defineEmits<{
  (e: "cancel"): void;
  (e: "confirm"): void;
}>();

function handleConfirm() {
  emits("confirm");
}
function handleCancel() {
  emits("cancel");
}
</script>
