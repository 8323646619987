<template>
  <template v-for="item in reports" :key="item.name">
    <CardItemDesktop
      v-if="mdAndUp"
      :item="item"
      :actions="true"
      :columns="3"
      :icon="{
        name: 'mdi-file-document-outline',
        buttonColor: 'surface-variant-bright',
        color: 'primary',
      }"
      v-model:selected="item.selected"
      @click.stop="handleSelectReport(item)"
    >
      <template #default="props">
        <span class="text-cs_on_surface_variant"> {{ item.name }}</span>
        <span class="text-cs_on_surface_variant" style="justify-self: center">
          {{ item.ownerName }}
        </span>
        <span
          style="justify-self: end"
          class="ml-2 text-cs_on_surface_variant text-overline"
        >
          <div v-if="props.data.isHovering">
            <v-btn
              icon
              width="40px"
              height="40px"
              variant="text"
              @click.stop="handleEditReport(item)"
            >
              <v-icon icon="mdi-pencil" color="tertiary"></v-icon>
            </v-btn>

            <v-btn
              icon
              width="40px"
              height="40px"
              variant="text"
              @click.stop="
                () => {
                  report = item;
                  confirmDeleteReport = true;
                }
              "
            >
              <v-icon icon="mdi-trash-can-outline" color="tertiary"></v-icon>
            </v-btn>
          </div>
          <time v-else :datetime="item.updatedAt.toDateString">{{
            formatDate(new Date(item.updatedAt))
          }}</time>
        </span>
      </template>
    </CardItemDesktop>

    <CardItemMobile
      v-else
      :item="item"
      :header="false"
      :icon="{
        name: 'mdi-file-document-outline',
        buttonColor: 'surface-variant-bright',
        color: 'primary',
      }"
      @doubletouch="handleSelectReport(item)"
    >
      <div>
        <h5>{{ item.name }}</h5>
        <span>{{ item.ownerName }}</span>
      </div>
      <div class="d-flex justify-end align-end h-100">
        <time datetime="">{{ formatDate(new Date(item.updatedAt)) }}</time>
      </div>

      <template #back>
        <div>
          <v-btn
            icon
            width="40px"
            height="40px"
            variant="text"
            @click.stop="handleEditReport(item)"
          >
            <v-icon icon="mdi-pencil" color="tertiary"></v-icon>
          </v-btn>

          <v-btn
            icon
            width="40px"
            height="40px"
            variant="text"
            @click.stop="
              () => {
                report = item;
                confirmDeleteReport = true;
              }
            "
          >
            <v-icon icon="mdi-trash-can-outline" color="tertiary"></v-icon>
          </v-btn>
        </div>
      </template>
    </CardItemMobile>
    <v-dialog v-model="confirmDeleteReport" width="auto">
      <DeleteModal
        v-if="confirmDeleteReport"
        @cancel="confirmDeleteReport = false"
        @confirm="handleDeleteReport"
      ></DeleteModal>
    </v-dialog>
  </template>
</template>

<script setup lang="ts">
import { useDate, useDisplay } from "vuetify/lib/framework.mjs";
import CardItemDesktop from "./CardItemDesktop.vue";
import CardItemMobile from "./CardItemMobile.vue";
import { computed, ref } from "vue";
import { ReportsStore } from "@/store/ReportsStore";
import DeleteModal from "../DeleteModal/DeleteModal.vue";
import { Report } from "@/api/resources/ReportsResource/nexusResource";

defineProps<{ reports: ReportsStore[] }>();

const emits = defineEmits<{
  (e: "delete", item: Report): void;
  (e: "edit", item: Report): void;
  (e: "select", item: Report): void;
}>();

const dateUtilityVuetify = useDate();
const { mdAndUp } = useDisplay();

const confirmDeleteReport = ref(false);
const report = ref<Report>();

function handleDeleteReport() {
  if (report.value) {
    emits("delete", report.value);
    confirmDeleteReport.value = false;
  }
}

function handleEditReport(report: Report) {
  emits("edit", report);
}

function handleSelectReport(report: Report) {
  emits("select", report);
}

function isTodayDate(date: Date) {
  const dateNow = new Date(date);
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  dateNow.setHours(0, 0, 0, 0);

  return dateNow.getTime() === todayDate.getTime();
}

const formatDate = (date: Date) =>
  computed(() => {
    return isTodayDate(date)
      ? dateUtilityVuetify.format(date, "fullTime12h")
      : dateUtilityVuetify.format(date, "fullDate");
  });
</script>
