<template>
  <v-card
    class="position-relative rounded-xl"
    elevation="1"
    v-double-touch="handleDoubleTouch"
  >
    <div class="d-flex justify-end">
      <div class="pa-4 bg-cs_ref_palette_information95"></div>
      <div
        class="pa-4 bg-cs_ref_palette_information95"
        :style="{
          height: cardItemRef?.getBoundingClientRect().height + 'px',
          borderRadius: '0px 24px 24px 0px',
        }"
        ref="hiddenContent"
      >
        <slot :data="{ item }" name="back">
          conteudo escondido askjdlaj askdladj
        </slot>
      </div>
    </div>
    <div
      v-ripple
      class="position-absolute top-0 left-0 right-0 pa-4"
      ref="cardItemRef"
      :class="modelSelected ? 'bg-cs_ref_palette_information95' : className"
    >
      <div
        class="header d-flex justify-space-between mb-2"
        v-if="header ?? true"
      >
        <slot :data="{ item }" name="header">
          <span class="text-caption">#123456789</span>
          <div>
            <v-icon icon="mdi-plus" size="8px"></v-icon>
            <span class="text-subtitle">Approved</span>
          </div>
        </slot>
      </div>
      <div class="content d-flex align-start">
        <div class="mr-4">
          <div v-if="selectable && modelSelected">
            <v-btn
              @click.stop="
                () => {
                  modelSelected = !modelSelected;
                }
              "
              icon
              color="tertiary-container"
              width="40px"
              height="40px"
            >
              <v-icon
                size="12px"
                icon="mdi-circle"
                class="text-cs_on_tertiary_container"
              ></v-icon>
            </v-btn>
          </div>
          <slot v-else :data="{ item, activeSelected }" name="icon">
            <v-btn
              width="40px"
              height="40px"
              icon
              @click.stop="activeSelected"
              @touchstart.stop="activeSelected"
              :color="icon?.buttonColor ?? 'primary'"
            >
              <v-icon
                :icon="icon?.name ?? 'mdi-plus'"
                size="24px"
                :color="icon?.color ?? 'on-primary'"
              ></v-icon>
            </v-btn>
          </slot>
        </div>
        <div
          class="flex-grow-1"
          :style="`display: grid;  grid-template-columns: repeat(${
            columns ?? 2
          }, 1fr); align-items:center; `"
        >
          <slot :data="{ item }">
            <div class="d-flex flex-column">
              <span>Long store name</span>
              <time datetime="">12:57 PM</time>
            </div>

            <div style="justify-self: end">$999,999.99</div>
          </slot>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts" generic="T">
import { swipeUtility } from "@clearsale/material-ds";
import { onMounted, ref } from "vue";
import { vDoubleTouch } from "@/directives/doubleTouch";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";

const props = withDefaults(
  defineProps<{
    header?: boolean;
    selectable?: boolean;
    columns?: number;
    icon?: { name?: string; color?: string; buttonColor?: string };
    item: T;
  }>(),
  { header: true }
);

const emits = defineEmits<{ (e: "doubletouch", item: T): void }>();

const modelSelected = defineModel<boolean>("selected");

const cardItemRef = ref<HTMLElement | null>(null);
const hiddenContent = ref<HTMLElement | null>(null);

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);

function activeSelected() {
  if (props.selectable) modelSelected.value = true;
}

function handleDoubleTouch() {
  emits("doubletouch", props.item);
}

onMounted(() => {
  if (cardItemRef.value && hiddenContent.value) {
    let swipe = swipeUtility(cardItemRef.value, {
      leftElement: hiddenContent.value,
      rightElement: cardItemRef.value,
      type: "LEFT",
    });

    // if (mobile && props.active) {
    //   swipe.hammer.destroy();
    //   return;
    // }
    swipe.onLoadEvents(swipe.hammer);
  }
});
</script>
