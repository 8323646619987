import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useDisplay, useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { AddButtonContentElements } from "./constants/AddButtonContent";
import { AddButtonStyleDesktop, AddButtonStyleMobile } from "./AddButton.style";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddButton',
  props: {
    icon: {},
    disabled: { type: Boolean }
  },
  setup(__props: any) {

const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();


return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    "prepend-icon": _ctx.icon ?? _unref(AddButtonContentElements).AddButtonContentIcon,
    class: "add-button text-primary",
    color: "surface-variant",
    "data-testeid": _unref(generateTestId)(_unref(AddButtonContentElements).AddButtonContent),
    elevation: !_unref(mdAndUp) ? 1 : 0,
    style: _normalizeStyle(_unref(mdAndUp) ? _unref(AddButtonStyleDesktop) : _unref(AddButtonStyleMobile)),
    position: _unref(mdAndUp) ? 'relative' : 'fixed',
    readonly: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_unref(AddButtonContentElements).AddButtonContentLabel), 1)
      ])
    ]),
    _: 3
  }, 8, ["prepend-icon", "data-testeid", "elevation", "style", "position", "readonly"]))
}
}

})