<template>
  <v-btn
    :prepend-icon="icon ?? AddButtonContentElements.AddButtonContentIcon"
    class="add-button text-primary"
    color="surface-variant"
    :data-testeid="generateTestId(AddButtonContentElements.AddButtonContent)"
    :elevation="!mdAndUp ? 1 : 0"
    :style="mdAndUp ? AddButtonStyleDesktop : AddButtonStyleMobile"
    :position="mdAndUp ? 'relative' : 'fixed'"
    :readonly="disabled"
  >
    <slot>
      {{ AddButtonContentElements.AddButtonContentLabel }}
    </slot>
  </v-btn>
</template>

<script lang="ts" setup>
import { useDisplay, useTheme } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { AddButtonContentElements } from "./constants/AddButtonContent";
import { AddButtonStyleDesktop, AddButtonStyleMobile } from "./AddButton.style";

const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();
defineProps<{ icon?: string; disabled?: boolean }>();
</script>

<style scoped lang="scss">
.add-button {
  text-transform: none;
}
</style>
