import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"background-color":"white","min-height":"100vh","display":"flex","flex-direction":"column"}
}
const _hoisted_2 = {
  style: {"max-width":"1040px"},
  class: "w-100 mx-auto"
}

import { onBeforeMount, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

import "@/plugins/gsap";

import OneMenu from "@/components/one/NewMenu/OneMenu.vue";
import FooterLogo from "@/components/shared/Footer/FooterLogo.vue";

import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import { validateSession } from "@/services/auth";
import { useReportsStore } from "@/store/ReportsStore";
import { enableMocking } from "@/api/mocks/browser";

import { useTheme } from "vuetify";

import imgNexus from "@/assets/logo_nexus.svg";
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from "@unhead/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const headInfo = useHead({
  title: "Nexus",
  link: [{ rel: "icon", href: imgNexus, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const { $patch } = useReportsStore();

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const { global } = useTheme();

let theme = ref();

const showApp = ref(false);
const router = useRouter();

const className = useClassByTheme(classTheme);

const activeSSO = process.env.VUE_APP_ENV !== "development";

addEventListener("access-token", () => {
  showApp.value = true;
});

const updateOrientation = () => {
  const angleDevice = screen.orientation.angle;

  if (angleDevice === 0) {
    $patch({ isLandScape: false });
    return;
  }

  $patch({ isLandScape: true });
};

onBeforeMount(() => {
  theme.value = localStorage.getItem("theme");
  global.name.value = theme.value !== "dark" ? "light" : "dark";
});

onMounted(() => {
  if (activeSSO && !validateSession()) {
    showApp.value = false;
    return;
  }
  showApp.value = true;
  screen.orientation.addEventListener("change", updateOrientation);
});

onUnmounted(() => {
  screen.orientation.removeEventListener("change", updateOrientation);

  headInfo.dispose();
});

const load = ref(false);

router.beforeResolve((to, from, next) => {
  enableMocking().finally(() => {
    next();
    load.value = true;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (showApp.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_theme_provider, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_unref(className)),
              style: {"flex":"1","display":"flex","flex-direction":"column"}
            }, [
              (load.value)
                ? (_openBlock(), _createBlock(OneMenu, {
                    key: 0,
                    class: "my-4"
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_RouterView)
              ])
            ], 2),
            _createVNode(FooterLogo, {
              class: _normalizeClass(_unref(className))
            }, null, 8, ["class"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})