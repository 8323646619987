import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-grow-1 w-100" }
const _hoisted_2 = { class: "mb-4 mt-2" }
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = {
  class: "d-flex flex-column flex-grow-1 w-100 mx-auto px-4 justify-center",
  style: {"max-width":"600px"}
}
const _hoisted_5 = {
  class: "ga-1 mb-6",
  style: {"display":"flex","flex-direction":"column"}
}
const _hoisted_6 = { class: "text-h6 text-cs_on_surface_variant" }
const _hoisted_7 = {
  class: "ga-1 mb-6",
  style: {"display":"flex","flex-direction":"column"}
}
const _hoisted_8 = { class: "text-h6 text-cs_on_surface_variant" }
const _hoisted_9 = {
  style: {"list-style":"none"},
  class: "d-flex flex-column ga-4 mt-6"
}

import {
  computed,
  CSSProperties,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import {
  onBeforeRouteLeave,
  RouteRecordNameGeneric,
  useRouter,
} from "vue-router";
import { useDisplay, useTheme } from "vuetify";
import { VBtn } from "vuetify/lib/components/index.mjs";

import imgHeroLight from "@/assets/backgrounds/BG-nexus-light.jpg";
import imgHeroDark from "@/assets/backgrounds/BG-nexus-dark.jpg";
import imgClearsale from "@/assets/logo_mobile.png";
import { useClassByTheme } from "@/components/hooks/useClassByTheme";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import WidthScrollContainer from "@/components/shared/WidthScrollContainer/WidthScrollContainer.vue";
import CardItemIMG from "@/components/nexus/CardItemIMG/CardItemIMG.vue";
import {
  postCreateReport,
  getListBaseReport,
  TemplateReports,
} from "@/api/resources/ReportsResource/nexusResource";

import BaseReportIMG from "@/assets/templates/base_report.png";
import BlankReportIMG from "@/assets/templates/blank_report.png";
import DiscardModal from "@/components/nexus/DiscardModal/DiscardModal.vue";
import { routeNames } from "@/router";
import { label } from "@/plugins/locales/global";
import { useI18n } from "vue-i18n";

interface TemplateReportsComponent extends TemplateReports {
  selected: boolean;
}

const scrollContainerWidth = 40;

export default /*@__PURE__*/_defineComponent({
  __name: 'NexusNewReportView',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();
const { mdAndUp } = useDisplay();

const templateName = ref("");
const templateReports = ref<TemplateReportsComponent[]>([]);
const enabledButton = ref(false);
const saveButtonHasClicked = ref(false);
const modalDiscard = ref(false);
const buttonDiscardHasClicked = ref(false);
const redirectRouterToDiscard = ref<RouteRecordNameGeneric>("");
const canAddNewReport = ref(true);

const imgClearsaleOptions = ref({
  src: imgClearsale,
  alt: "Logo Clear Sale",
});

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};

const { name: themeName } = useTheme();
const className = useClassByTheme(classTheme);

const imgHero = computed(() => {
  return themeName.value === "light" ? imgHeroLight : imgHeroDark;
});

const inputTemplateNameRules = {
  required: (inputValue: string) => !!inputValue,
  maxLength: (inputValue: string) => inputValue && inputValue.length <= 50,
};
const inputTemplateNameReturnMessageRules = {
  required: (inputValue: string) => !!inputValue || "Name is required",
  maxLength: (inputValue: string) =>
    (inputValue && inputValue.length <= 50) ||
    "Name must be less than 50 characters",
};

const templateContent = {
  "Base report": {
    src: BaseReportIMG,
    title: label.AddNewPage.Template.BaseReport.title,
    description: label.AddNewPage.Template.BaseReport.content,
  },
  "Blank report": {
    src: BlankReportIMG,
    title: label.AddNewPage.Template.BlankReport.title,
    description: label.AddNewPage.Template.BlankReport.content,
  },
};

const getReportSelected = computed(() => {
  return templateReports.value.filter((report) => report.selected);
});

const hasModifiedForm = computed(() => {
  const hasOneSelected = templateReports.value.some(
    (report) => report.selected
  );
  return (
    hasOneSelected ||
    (inputTemplateNameRules.required(templateName.value) &&
      inputTemplateNameRules.maxLength(templateName.value))
  );
});

const hasValidateForm = computed(() => {
  const hasOneSelected = templateReports.value.some(
    (report) => report.selected
  );
  return (
    hasOneSelected &&
    inputTemplateNameRules.required(templateName.value) &&
    inputTemplateNameRules.maxLength(templateName.value)
  );
});

function handleBackButton() {
  if (!hasModifiedForm.value) {
    router.back();
    return;
  }

  redirectRouterToDiscard.value = routeNames.reports;
  modalDiscard.value = true;
}

function handleSelectTemplateReport(report: TemplateReports): void {
  templateReports.value = templateReports.value.map((reportMap) => {
    if (reportMap.baseReportId === report.baseReportId) {
      return { ...reportMap, selected: true };
    }
    return { ...reportMap, selected: false };
  });
}

const refIcon = ref<VBtn | undefined>(undefined);
let originalIconDomRect: DOMRect | undefined = undefined;
const elementOnTop = ref(false);
const styleObj = reactive<CSSProperties>({
  transition: "all ease-in-out 0.3s",
});

function handleScrollOnTop(isOnTop: boolean): void {
  if (isOnTop) {
    elementOnTop.value = true;
    styleObj.height = `${scrollContainerWidth}px`;
    styleObj.width = `${scrollContainerWidth}px`;
  } else {
    elementOnTop.value = false;
    if (originalIconDomRect) {
      styleObj.height = `${originalIconDomRect.height}px`;
      styleObj.width = `${originalIconDomRect.width}px`;
    }
  }
}

function handleSaveReport() {
  postCreateReport({
    name: templateName.value,
    baseReportId: getReportSelected.value[0].baseReportId,
  }).then((r) => {
    saveButtonHasClicked.value = true;
    router.push({
      name: routeNames.editReport,
      params: { id: r.reportId },
    });
  });
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
};

function handleDiscardNewReport() {
  buttonDiscardHasClicked.value = true;
  modalDiscard.value = false;
  router.push({
    name: redirectRouterToDiscard.value,
  });
}

onMounted(() => {
  window.addEventListener("beforeunload", handleBeforeUnload);
});

onUnmounted(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

onMounted(() => {
  getListBaseReport().then((response) => {
    canAddNewReport.value = response.canAddNewReport;

    if (response.results.length === 0) {
      router.replace({
        name: routeNames.reports,
      });
      return;
    }

    templateReports.value = response.results.map((report) => ({
      ...report,
      selected: false,
    }));

    if (!canAddNewReport.value) {
      router.replace({
        name: routeNames.reports,
      });
    }
  });
});

watch(
  [templateName, templateReports],
  () => {
    if (hasValidateForm.value) {
      enabledButton.value = true;
      return;
    }
    enabledButton.value = false;
  },
  { deep: true, immediate: true }
);

onBeforeRouteLeave(async (to, from, next) => {
  redirectRouterToDiscard.value = to.name;

  if (hasModifiedForm.value) {
    if (
      buttonDiscardHasClicked.value ||
      (saveButtonHasClicked.value && hasValidateForm.value)
    ) {
      next();
      return;
    }
    modalDiscard.value = true;
    next(false);
    return;
  }
  next();
});

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(_unref(mdAndUp) || 'px-4'),
        style: _normalizeStyle({ height: _unref(mdAndUp) ? '220px' : '120px' })
      }, [
        _createVNode(CustomHero, {
          label: _unref(label).AddNewPage.title,
          "src-image": imgHero.value
        }, null, 8, ["label", "src-image"])
      ], 6),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(WidthScrollContainer, {
          width: "600px",
          resizeBy: "600px",
          height: elementOnTop.value ? 48 : 58,
          "pined-style": elementOnTop.value ? 'z-index:9999;' : 'height: auto',
          class: _normalizeClass(_unref(className)),
          style: {"padding":"0px"},
          onScrollOnTop: handleScrollOnTop
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["d-flex justify-space-between", _unref(mdAndUp) || 'px-4'])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(VBtn), {
                  color: "cs_surface_container",
                  icon: "mdi-arrow-left",
                  elevation: "0",
                  size: elementOnTop.value ? '40px' : '48px',
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (handleBackButton())),
                  ref_key: "refIcon",
                  ref: refIcon,
                  "data-testid": "back-button"
                }, null, 8, ["size"])
              ]),
              _createVNode(_unref(VBtn), {
                class: "text-primary save-button",
                color: _unref(themeName) === 'dark' ? 'surface' : 'surface-variant',
                style: _normalizeStyle({ height: elementOnTop.value ? '40px' : '48px' }),
                elevation: "0",
                "prepend-icon": "mdi-content-save-outline",
                label: "Save",
                readonly: !enabledButton.value,
                onClick: handleSaveReport
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)(_unref(label).AddNewPage.Save.title)), 1)
                ]),
                _: 1
              }, 8, ["color", "style", "readonly"])
            ], 2)
          ]),
          _: 1
        }, 8, ["height", "pined-style", "class"])
      ]),
      _createElementVNode("section", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h5", _hoisted_6, _toDisplayString(_unref(t)(_unref(label).AddNewPage.information)), 1),
          _createVNode(_component_v_card, {
            class: _normalizeClass(["w-100 pa-4 rounded-xl", _unref(className)]),
            elevation: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: templateName.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((templateName).value = $event)),
                class: "mt-6",
                variant: "outlined",
                label: _unref(t)(_unref(label).AddNewPage.name),
                rules: [
              inputTemplateNameReturnMessageRules.required,
              inputTemplateNameReturnMessageRules.maxLength,
            ]
              }, null, 8, ["modelValue", "label", "rules"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h5", _hoisted_8, _toDisplayString(_unref(t)(_unref(label).AddNewPage.Template.title)), 1),
          _createVNode(_component_v_card, {
            class: _normalizeClass(["pa-4 rounded-xl", _unref(className)]),
            elevation: "1"
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(templateReports.value, (report) => {
                  return (_openBlock(), _createBlock(CardItemIMG, {
                    key: report.baseReportId,
                    onClick: ($event: any) => (handleSelectTemplateReport(report)),
                    title: _unref(t)(templateContent[report.tag as keyof typeof templateContent].title),
                    subtitle: _unref(t)(templateContent[report.tag as keyof typeof templateContent].description),
                    img: { src: templateContent[report.tag as keyof typeof templateContent].src },
                    active: report.selected
                  }, null, 8, ["onClick", "title", "subtitle", "img", "active"]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["class"])
        ])
      ])
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: modalDiscard.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((modalDiscard).value = $event)),
      width: "auto"
    }, {
      default: _withCtx(() => [
        (modalDiscard.value)
          ? (_openBlock(), _createBlock(DiscardModal, {
              key: 0,
              onCancel: _cache[2] || (_cache[2] = ($event: any) => (modalDiscard.value = false)),
              onConfirm: handleDiscardNewReport
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})