<template>
  <v-card
    class="rounded-circle d-flex justify-center align-center cursor-pointer"
    :class="className"
    style="width: 240px; height: 240px"
  >
    <slot></slot>
  </v-card>
</template>

<script setup lang="ts">
import { useClassByTheme } from "@/components/hooks/useClassByTheme";

const classTheme = {
  light: "bg-cs_on_primary",
  dark: "bg-cs_surface_container_low",
};
const className = useClassByTheme(classTheme);
</script>
